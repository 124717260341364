import { essentialLocales, baseLocales, languageFlags } from '@fs/zion-locale'

// Copied code from zion-locale because they don't export it.
const experimentalLocales = [...languageFlags.essential, ...languageFlags.extended, ...languageFlags.supported].map(
  (entry) => entry.locale
)

const manuallyAddedLocales = ['eo']
export const appSupportedLocales = [
  ...essentialLocales,
  ...baseLocales,
  ...manuallyAddedLocales,
  ...experimentalLocales,
]

const notSupportedS3Locales = ['ceb', 'fj', 'ti', 'uk', 'mg', 'ms', 'et', 'hr', 'lv', 'tl']
const supportedEssentialLocales = essentialLocales.filter((locale) => !notSupportedS3Locales.includes(locale))
// These are the ones that are found in the localized data from what the backend give us
export const searchSupportedLocales = [...baseLocales, ...supportedEssentialLocales]
