import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { usePageView } from '@fs/zion-analytics'
import Head, { Title } from '@fs/zion-head'
import { useLocation, useParams } from '@fs/zion-router'
import { AppLayout, colors, Divider, LayoutBand, Separator, Skeleton, DataPreview, H1 } from '@fs/zion-ui'
import useSurnameIndex, { useSurnameIndexStatus } from '../../services/surname-index-service'
import IndexNav from './IndexNav'
import IndexFooter from './IndexFooter'
import IndexList from './IndexList'
import PersonList from './PersonList'
import NotFoundError from '../errors/NotFoundError'
import ServerError from '../errors/ServerError'

const centeredCss = css`
  margin: 16px auto 0 auto;

  @media screen and (min-width: 768px) {
    padding: 0 32px;
  }
`

const messageCss = css`
  display: grid;
  grid-template-columns: min-content max-content;
  align-items: center;
  margin: 40px auto 0;
  grid-column-gap: 12px;
  width: max-content;
`

const contentCss = css`
  background-color: ${colors.gray00};
  padding: 8px 0;
`

const footerCss = css`
  margin-top: 16px;
`

const errorFooterCss = css`
  margin: 16px auto;
  text-align: center;
`

export default function IndexPage(): JSX.Element {
  const [t] = useTranslation()
  const dataState = useSurnameIndexStatus()
  const data = useSurnameIndex()
  const { surnames } = useParams()
  const location = useLocation()
  const pageName = `Surname Index${surnames && location.pathname.includes('/leaf') ? ': Person List' : ''}`

  usePageView({ page_detail: pageName })

  if (dataState.notFound)
    return (
      <>
        <div css={messageCss}>
          <NotFoundError />
        </div>
        <div css={errorFooterCss}>
          <IndexFooter />
        </div>
      </>
    )

  if (dataState.serverError)
    return (
      <>
        <div css={messageCss}>
          <ServerError />
        </div>
        <div css={errorFooterCss}>
          <IndexFooter />
        </div>
      </>
    )

  if (dataState.error) throw dataState.error // Let ErrorBoundary catch this error

  return (
    <AppLayout fullWidth backgroundColor={colors.gray00}>
      <Title>{t('ancestors.surname-index.meta-data.title')}</Title>
      <Head>
        <meta name="robots" content="noindex, follow" />
      </Head>
      <LayoutBand minHeight={700}>
        <div css={centeredCss}>
          <H1 data-testid="ancestors-heading" size="H3">
            {t('ancestors.surname-index.header')}
          </H1>

          <DataPreview.Loading loading={dataState.loading}>
            <div style={{ height: 56, padding: '10px 0' }}>
              <Skeleton.Paragraph maxWidth={Math.random() * 50 + 500} size="sm" />
            </div>
            <Divider />
            <Separator size="xs" />
            <div style={{ marginLeft: 16 }} data-testid="index-loading">
              {Array.from({ length: 20 }).map((_v, index) => (
                // eslint-disable-next-line react/no-array-index-key -- Tis readonly so it is fine
                <Skeleton.Paragraph key={index} maxWidth={Math.random() * 50 + 500} size="sm" />
              ))}
            </div>
          </DataPreview.Loading>

          <DataPreview.Ready loading={dataState.loading}>
            <IndexNav items={data?.navigation} />

            <div css={contentCss}>
              <Divider />
              <Separator size="xs" />
              {data?.surnames && <IndexList items={data.surnames} />}
              {data?.persons && <PersonList items={data.persons} />}
            </div>
            <Divider />
            <div css={footerCss}>
              <IndexFooter />
            </div>
          </DataPreview.Ready>
        </div>
      </LayoutBand>
    </AppLayout>
  )
}
