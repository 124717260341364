import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from '@fs/zion-router'
import { colors, List, ListItem, Paragraph, useContainerWidth } from '@fs/zion-ui'
import { trackEvent } from '@fs/zion-analytics'
import type { SurnameIndexItem } from '../../types/data-types'
import { getPathFromUrl } from '../../services/url-service'
import { getName } from '../../services/surname-index-service'

const rangeCss = css`
  color: ${colors.blue50};
`

export default function IndexList({ items }: { items: SurnameIndexItem[] | undefined }): JSX.Element {
  const [t] = useTranslation()
  const history = useHistory()
  const atWidth = useContainerWidth()
  const isMobile = atWidth({ default: true, md: false })
  return (
    <div>
      <List>
        {items &&
          items.map((item, index) => {
            const key = `${index}`
            return (
              <ListItem
                data-testid="surname-list-item"
                primaryText={
                  <Paragraph size="sm">
                    <span css={rangeCss}>{getName(t, item.givenNamesRangeStart, item.surnameRangeStart)}</span>
                    <span> ({item.lifespanRangeStart}) - </span>
                    <span css={rangeCss}>{getName(t, item.givenNamesRangeEnd, item.surnameRangeEnd)}</span>
                    <span> ({item.lifespanRangeEnd})</span>
                  </Paragraph>
                }
                onClick={() => {
                  trackEvent({ link_name: 'List Item' })
                  window?.scrollTo && window?.scrollTo(0, 0)
                  history.push(getPathFromUrl(item.url, true))
                }}
                key={key}
                dense={!isMobile}
              />
            )
          })}
      </List>
    </div>
  )
}
