import bg from './logos/lds-logo_bg.svg'
import cs from './logos/lds-logo_cs.svg'
import da from './logos/lds-logo_da.svg'
import de from './logos/lds-logo_de.svg'
import el from './logos/lds-logo_el.svg'
import en from './logos/lds-logo_en.svg'
import es from './logos/lds-logo_es.svg'
import et from './logos/lds-logo_et.svg'
import fi from './logos/lds-logo_fi.svg'
import fj from './logos/lds-logo_fj.svg'
import fr from './logos/lds-logo_fr.svg'
import hr from './logos/lds-logo_hr.svg'
import ht from './logos/lds-logo_ht.svg'
import hu from './logos/lds-logo_hu.svg'
import hy from './logos/lds-logo_hy.svg'
import id from './logos/lds-logo_id.svg'
import is from './logos/lds-logo_is.svg'
import it from './logos/lds-logo_it.svg'
import ja from './logos/lds-logo_ja.svg'
import km from './logos/lds-logo_km.svg'
import ko from './logos/lds-logo_ko.svg'
import lt from './logos/lds-logo_lt.svg'
import lv from './logos/lds-logo_lv.svg'
import mk from './logos/lds-logo_mk.svg'
import mn from './logos/lds-logo_mn.svg'
import ms from './logos/lds-logo_ms.svg'
import nl from './logos/lds-logo_nl.svg'
import no from './logos/lds-logo_no.svg'
import pl from './logos/lds-logo_pl.svg'
import pt from './logos/lds-logo_pt.svg'
import ro from './logos/lds-logo_ro.svg'
import ru from './logos/lds-logo_ru.svg'
import sk from './logos/lds-logo_sk.svg'
import sl from './logos/lds-logo_sl.svg'
import sm from './logos/lds-logo_sm.svg'
import sq from './logos/lds-logo_sq.svg'
import sr from './logos/lds-logo_sr.svg'
import sv from './logos/lds-logo_sv.svg'
import th from './logos/lds-logo_th.svg'
import to from './logos/lds-logo_to.svg'
import uk from './logos/lds-logo_uk.svg'
import vi from './logos/lds-logo_vi.svg'
import zhHans from './logos/lds-logo_zh-hans.svg'
import zh from './logos/lds-logo_zh.svg'

export default {
  bg,
  cs,
  da,
  de,
  el,
  en,
  es,
  et,
  fi,
  fj,
  fr,
  hr,
  ht,
  hu,
  hy,
  id,
  is,
  it,
  ja,
  km,
  ko,
  lt,
  lv,
  mk,
  mn,
  ms,
  nl,
  no,
  pl,
  pt,
  ro,
  ru,
  sk,
  sl,
  sm,
  sq,
  sr,
  sv,
  th,
  to,
  uk,
  vi,
  'zh-hans': zhHans,
  zh,
}
