import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from '@fs/zion-router'
import { colors, LayoutBand, Separator } from '@fs/zion-ui'
import { getCachedSearchParams, setCachedSearchParams } from '../../services/search-service'
import Search from '../search/Search'
import AncestorSocial from './AncestorSocial'
import type { Person } from '../../types/persons'
import type { SearchParams } from '../../types/data-types'

export default function AncestorPageFooter({ person }: { person: Person }): JSX.Element {
  const [t] = useTranslation()
  const history = useHistory()
  const initialParams = getCachedSearchParams()

  const onSearchSubmit = useCallback(
    (params: SearchParams): void => {
      setCachedSearchParams(params)
      history.push('/search')
    },
    [history]
  )

  return (
    <LayoutBand color={colors.gray00} maxWidth="xs">
      <Search
        header={t('ancestors.search-for-another-ancestor.header')}
        onSubmit={onSearchSubmit}
        params={initialParams}
        data-testid="ancestor-page-footer-search-form"
      />
      <Separator size="xs" />
      <AncestorSocial person={person} />
    </LayoutBand>
  )
}
