import type { TfPerson, TfRelationshipEvent, TfEventPlace, LocalizedData } from '@fs/zion-tree-types'
import type {
  EventConclusion,
  RelationshipEventConclusion,
  VitalEventType,
  RelationshipEventType,
} from '../types/conclusions'

export function convertEvent(
  type: VitalEventType,
  s3Person: TfPerson,
  localizedData: LocalizedData
): EventConclusion | undefined {
  const { events } = s3Person
  const lowerCaseType = type.toLowerCase()

  const s3Event = events?.find((event) => event.name.toLowerCase() === lowerCaseType)
  const s3EventPlace: TfEventPlace | undefined = s3Person[`${lowerCaseType}Place`]
  const s3DateValue = s3Person[`${lowerCaseType}Date`] || s3Event?.date
  const s3Date = s3DateValue
    ? {
        formalText: s3DateValue,
        localizedText: s3DateValue,
        originalText: s3DateValue,
      }
    : undefined

  const s3Place = s3EventPlace && {
    geoCode: s3EventPlace.geo,
    localizedText: localizedData[s3EventPlace.address] as string,
    originalText: localizedData[s3EventPlace.address] as string,
  }

  if (!s3Place && !s3Date) return undefined
  return {
    type,
    details: {
      date: s3Date,
      place: s3Place,
    },
  }
}

export function convertRelationshipEvent(event: TfRelationshipEvent): RelationshipEventConclusion {
  const { id, date, place, name, year } = event

  const relationshipEvent: RelationshipEventConclusion = {
    id,
    type: name.toUpperCase() as RelationshipEventType,
    details: { year },
  }

  if (date)
    relationshipEvent.details.date = {
      localizedText: date,
      originalText: date,
    }

  if (place)
    relationshipEvent.details.place = {
      localizedText: place,
      originalText: place,
    }

  return relationshipEvent
}
