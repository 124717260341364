import React from 'react'
import { Button, DialogOverlay, DialogOverlayActions, DialogOverlayFooter, Paragraph } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import type { UseOverlayReturnOptions } from '@fs/zion-ui/dist/cjs/overlay/useOverlay'
import type { Person } from '../types/persons'
import { getExternalBaseUrl } from '../services/url-service'
import { useDataContext } from '../services/data-service'

export default function NotPublished({
  person,
  overlay,
}: {
  person: Person
  overlay: UseOverlayReturnOptions
}): JSX.Element {
  const [t] = useTranslation()
  const baseUrl = getExternalBaseUrl()
  const dataContext = useDataContext()
  const { store: dataState } = dataContext
  const { CF } = dataState
  return (
    <DialogOverlay {...overlay} title={person.name}>
      <Paragraph size="sm">
        {CF ? t('ancestors.unavailable.text') : t('ancestors.person.not-published', { personName: person.name })}
      </Paragraph>
      <DialogOverlayFooter>
        {CF ? (
          <DialogOverlayActions>
            <Button onClick={overlay.handleClick} data-testid="not-available-close">
              {t('common-ui:close.action')}
            </Button>
          </DialogOverlayActions>
        ) : (
          <DialogOverlayActions>
            <Button
              data-testid="sign-in-button"
              to={`${baseUrl}/auth/familysearch/login?returnurl=${encodeURIComponent(
                `${baseUrl}/tree/person/details/${person.id}`
              )}`}
              external
            >
              {t('common-ui:sign-in.action')}
            </Button>
            <Button data-testid="create-account-button" emphasis="high" to={`${baseUrl}/register`} external>
              {t('ancestors.create-free-account.action')}
            </Button>
          </DialogOverlayActions>
        )}
      </DialogOverlayFooter>
    </DialogOverlay>
  )
}
