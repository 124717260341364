import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { usePageView } from '@fs/zion-analytics'
import { NoticeLoading } from '@fs/zion-icon'
import { i18n } from '@fs/zion-locale'
import { useHistory, useParams } from '@fs/zion-router'
import { useUser } from '@fs/zion-user'
import { LayoutBand, Separator } from '@fs/zion-ui'
import {
  PersonMasonryLayout,
  ActivitiesCard,
  CreateAccountCard,
  FamilyCard,
  AncestorsLifeHistoryCard,
  MemoriesCard,
  NameMeaningCard,
  SourcesCard,
  FamilyTimelineCard,
  WorldEventsCard,
  StoryCard,
} from '@fs/zion-tree-person-info-cards'
import type { History } from 'history'
import { useDataContext } from '../../services/data-service'
import useMemories from '../../services/useMemories'
import useNameMeanings from '../../services/useNameMeanings'
import useLifeOf from '../../services/useLifeOf'
import useFamilies from '../../services/useFamilies'
import useSources from '../../services/useSources'
import useWorldEvents from '../../services/useWorldEvents'
import useTimeline from '../../services/useTimeline'
import useStoryCard from '../../services/useStoryCard'
import NotFoundError from '../errors/NotFoundError'
import { getExternalBaseUrl } from '../../services/url-service'
import type { TimelineItems } from '../../types/data-types'
import GoneError from '../errors/GoneError'
import MovedError from '../errors/MovedError'
import NotPublished from '../NotPublished'
import PageHead from '../PageHead'
import AncestorPageFooter from './AncestorPageFooter'
import PersonBanner from '../person-banner/PersonBanner'

const noBlowoutCss = css`
  & > div > div {
    min-width: 0;
  }
`

const messageCss = css`
  display: grid;
  grid-template-columns: min-content max-content;
  align-items: center;
  margin: 40px auto 0;
  grid-column-gap: 12px;
  width: max-content;
`

export function displayTimeline(timelineItems: TimelineItems | undefined): boolean {
  let hasValidParentStart = false
  let hasValidSpouseStart = false

  timelineItems?.parentFamily?.forEach((item) => {
    if (item.start && item.start > 0) hasValidParentStart = true
  })
  timelineItems?.spouseFamily?.forEach((item) => {
    if (item.start && item.start > 0) hasValidSpouseStart = true
  })

  const hasValidParentFamily = (timelineItems?.parentFamily?.length || 0) > 0 && hasValidParentStart
  const hasValidSpouseFamily = (timelineItems?.spouseFamily?.length || 0) > 0 && hasValidSpouseStart

  return hasValidParentFamily || hasValidSpouseFamily
}

export default function AncestorPage(): JSX.Element | null {
  const [t] = useTranslation()
  const dataContext = useDataContext()
  const { store: dataState } = dataContext
  const person = dataState?.data?.person
  const memories = useMemories()
  const nameMeanings = useNameMeanings()
  const lifeOf = useLifeOf()
  const families = useFamilies()
  const worldEvents = useWorldEvents()
  const sources = useSources()
  const popularStory = useStoryCard()
  const timelineItems = useTimeline()
  const { signedIn } = useUser()
  const canDisplayTimeline = displayTimeline(timelineItems)
  const history: History = useHistory()
  const { nameAndLifespan } = useParams()
  const { CF, pid } = dataState

  const baseUrl = getExternalBaseUrl()

  usePageView({ page_detail: 'Person' })

  const encodedNameAndLifespan = person?.url?.substring((person?.url?.lastIndexOf('/') ?? 0) + 1).replace(/\+/g, ' ')
  const decodedNameAndLifespan = decodeURIComponent(encodedNameAndLifespan || '')
  // Route /:pid to /:pid/:nameAndLifespan, but only if not server-side rendering
  const nameLifespanNotInURL = decodeURIComponent(nameAndLifespan) !== decodedNameAndLifespan

  if (person?.url && (!nameAndLifespan || nameLifespanNotInURL) && !process.env.SSR) {
    history.replace(
      `${window?.location?.hostname === 'localhost' ? `/${i18n.language}` : ''}/${person.id}/${encodeURIComponent(
        decodedNameAndLifespan
      )}${history.location.search}`
    )
    return null
  }

  if (dataState.loading)
    return (
      <div css={messageCss} data-testid="loading">
        <NoticeLoading size="lg" />
      </div>
    )

  if (dataState.notFound) return <NotFoundError personId={pid} />

  if (dataState.gone) return <GoneError personId={pid} CF={CF} />

  if (dataState.movedTo) return <MovedError location={dataState.movedTo} />

  if (dataState.error) throw dataState.error // Let ErrorBoundary catch this error

  if (!person) return null

  const pageTitle = t('ancestors.person.meta-data.title', {
    personName: person.name,
    lifespan: person.lifespan,
  })
  const pageDescription = t('ancestors.person.meta-data.description', {
    personName: person.name,
    lifespan: person.lifespan,
    address: person?.birth?.details?.place?.localizedText || '',
  })

  const socialShareTitle = t('ancestors.person.meta-data.social-share-title', {
    personName: person.name,
    lifespan: person.lifespan,
  })
  const socialShareDescription = t('ancestors.person.meta-data.social-share-description', {
    personName: person.name,
    lifespan: person.lifespan,
    address: person?.birth?.details?.place?.localizedText || '',
  })

  const image = person.portraitUrl ? { url: person.portraitUrl, alt: pageTitle, width: 200, height: 200 } : undefined

  const cfSourcesToDisplay = sources?.sources?.filter((source) => {
    return (
      // these are the same filters used in @fs/zion-tree-person-info-cards in MediaOverlay.js
      (!source.sourceImageLink && !source.sourceThumbnailLink) ||
      (source.sourceImageLink && source.imagePublic) ||
      (!source.sourceImageLink && source.sourceThumbnailLink && source.thumbnailPublic)
    )
  })

  // TODO: make all logged in be false when the following if statement no longer depends on r9PersonEx
  // Note: a 404 page will show if cookie is not set on person page.
  // TODO: Re-enable this when this jira is resolved: https://fhjira.churchofjesuschrist.org/browse/ZIONUI-1276
  // if (r9PersonEx && signedIn && /^ancestors/.test(window.location.hostname)) {
  //   // ancestorsbeta.familysearch.org just redirects to the production site.
  //   if (window.location.hostname === 'ancestors-integ.familysearch.org') {
  //     window.location.replace(`https://integration.familysearch.org/tree/person/about/${person.id}`)
  //   } else {
  //     window.location.replace(`https://www.familysearch.org/tree/person/about/${person.id}`)
  //   }
  // }
  return (
    <>
      <script>window.ANCESTORS_URL = &apos;{person.url}&apos;; </script>
      <PageHead
        title={pageTitle}
        description={pageDescription}
        socialShareTitle={socialShareTitle}
        socialShareDescription={socialShareDescription}
        url={person.url}
        image={image}
        person={person}
        families={families}
      />

      <PersonBanner person={person} displaySocial={!CF} />

      <Separator size="xs" />

      <LayoutBand maxWidth="lg">
        <PersonMasonryLayout>
          {lifeOf && <AncestorsLifeHistoryCard data-testid="life-of-card" person={person} {...lifeOf} />}

          <MemoriesCard
            data-testid="memories-card"
            memories={CF ? memories?.memoryArtifacts : memories?.memoryArtifacts?.slice(0, 6)}
            memoryCount={CF ? memories?.memoryArtifacts?.length || 0 : memories?.memoriesCount || 0} // when in CF mode, the memoriesCount and the number of memories we actually get back is not the same.
            person={person}
            baseUrl={baseUrl}
            loggedIn={signedIn}
            restricted={CF}
          />

          {canDisplayTimeline && (
            <div css={noBlowoutCss}>
              <FamilyTimelineCard data-testid="timeline-card" timelineItems={timelineItems} />
            </div>
          )}

          <SourcesCard
            data-testid="sources-card"
            sources={CF ? cfSourcesToDisplay || [] : sources?.sources}
            sourcesCount={CF ? cfSourcesToDisplay?.length || 0 : sources?.sourcesCount}
            person={person}
            loggedIn={signedIn}
            restricted={CF}
            baseUrl={baseUrl}
          />

          {families && families.spouses?.[0] && (
            <div css={noBlowoutCss}>
              <FamilyCard
                data-testid="couple-family-card"
                families={families}
                focusFamily={families.spouses?.[0]}
                type="couple"
                focusPersonId={person.id}
                customPersonLinkOrOverlay={NotPublished}
              />
            </div>
          )}

          {families && families.parents?.[0] && (
            <div css={noBlowoutCss}>
              <FamilyCard
                data-testid="parents-family-card"
                families={families}
                focusFamily={families.parents?.[0]}
                type="parents"
                focusPersonId={person.id}
                customPersonLinkOrOverlay={NotPublished}
              />
            </div>
          )}

          {worldEvents && worldEvents.length > 0 && (
            <WorldEventsCard data-testid="world-events-card" events={worldEvents} person={person} />
          )}

          {nameMeanings?.length && <NameMeaningCard data-testid="name-meaning-card" meanings={nameMeanings} />}

          {popularStory && (
            <StoryCard
              data-testid="story-card"
              data={popularStory}
              person={person}
              baseUrl={baseUrl}
              loggedIn={signedIn}
              restricted={CF}
            />
          )}

          {!CF && <ActivitiesCard data-testid="activities-card" person={person} baseUrl={baseUrl} />}

          {!signedIn && !CF && <CreateAccountCard data-testid="create-account-card" baseUrl={baseUrl} />}
        </PersonMasonryLayout>
      </LayoutBand>

      <Separator size="xs" />
      <div style={{ flexGrow: 1 }} />
      {!CF && <AncestorPageFooter person={person} />}
    </>
  )
}
