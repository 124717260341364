import useData from './data-service'
import type { StartEndDate, TimelineItem, TimelineItems, TimelinePerson } from '../types/data-types'
import type { Family } from '../types/families'
import type { Person } from '../types/persons'

export default function useTimeline(): TimelineItems | undefined {
  const data = useData()
  if (!data) return undefined

  return {
    spouseFamily: mungeTimelineData(data.families?.spouses?.[0], data.person),
    parentFamily: mungeTimelineData(data.families?.parents?.[0], data.person),
  }
}

// eslint-disable-next-line sonarjs/cognitive-complexity -- what do you expect from a munger...
export function mungeTimelineData(family: Family | undefined, person: Person): TimelineItem[] | undefined {
  let parent1
  let parent2
  let oldestChildStartYear
  let youngestParentStartYear
  const children: TimelineItem[] = []
  const timelineItems: TimelineItem[] = []

  // PARENT 1
  if (family?.parent1) {
    const { start, end } = getStartEndYears(family.parent1)
    // Set parent1 as the youngest parent to start
    youngestParentStartYear = start

    parent1 = {
      start,
      end,
      id: family.parent1.id,
      type: 'PARENT1',
      focusItem: person && family.parent1.id && family.parent1.id === person.id,
      personData: { ...family.parent1 },
    }

    if (hasValidYear(family?.parent1)) {
      timelineItems.push(parent1)
    }
  }

  // PARENT 2
  if (family?.parent2) {
    const years = getStartEndYears(family.parent2)
    let { start } = years
    const { end } = years

    // If parent1 didn't have a start or parent2 is younger
    if (!youngestParentStartYear || (start && start > youngestParentStartYear)) {
      youngestParentStartYear = start
    }

    // If parent2 does not have a start, use parent1 start
    if (!start && parent1?.start) start = parent1.start

    // If parent1 does not have a start, use parent2 start
    if (parent1 && !parent1.start) parent1.start = start

    parent2 = {
      start,
      end,
      id: family.parent2.id,
      type: 'PARENT2',
      focusItem: person && family.parent2.id && family.parent2.id === person.id,
      personData: { ...family.parent2 },
    }
    if (hasValidYear(family?.parent2)) {
      timelineItems.push(parent2)
    }
  }

  // EVENTS
  if (family?.event) {
    const event: TimelineItem = {
      start: parseInt(family?.event?.details?.year || '', 10),
      end: parseInt(family?.event?.details?.year || '', 10),
      id: family.event.id || '',
      type: 'MARRIAGE',
      focusItem: false,
      eventData: { ...family.event },
    }
    timelineItems.push(event)
  }

  // CHILDREN
  if (family?.children?.length) {
    family.children.forEach((child) => {
      const { start, end } = getStartEndYears(child)
      const timelineChild = child as TimelinePerson

      // If no start is returned, we will need to calculate it
      if (!start) timelineChild.calculatedStart = true

      // Set/Reset the oldestChilds start year
      if (start && (!oldestChildStartYear || start < oldestChildStartYear)) {
        oldestChildStartYear = start
      }

      const newChild = {
        start,
        end,
        id: child.id,
        type: 'CHILD',
        focusItem: !!(person && child.id && child.id === person.id),
        personData: { ...child },
      }
      if (hasValidYear(child)) {
        children.push(newChild)
      }
    })
  }

  // If none of the parents have start dates, get oldest child's start year
  // Set parents to be 20 years before oldest child
  if (parent1 && !parent1.start && !parent2?.start && oldestChildStartYear) {
    parent1.start = oldestChildStartYear - 20
    if (parent2) parent2.start = parent1.start
  }
  // If parent1 doesn't exist, and parent2 doesn't have a start
  if (parent2 && !parent2.start && !parent1 && oldestChildStartYear) {
    parent2.start = oldestChildStartYear - 20
    if (parent1) parent1.start = parent2.start
  }

  // If none of the children have start dates, get youngest parents's start year
  // Set children to be 20 years after youngest parent
  children?.forEach((child) => {
    if (!oldestChildStartYear && youngestParentStartYear) {
      child.start = youngestParentStartYear + 20
    } else if (!child.start && oldestChildStartYear) {
      child.start = oldestChildStartYear
    }
  })

  // Sort children
  children?.sort((child1, child2) => {
    if (child1?.calculatedStart) return 1
    if (child1.start && child2.calculatedStart) return -1
    if (child2.start && child1.calculatedStart) return 1
    if (child1.start && child2.start && child1?.start > child2.start) return 1
    if (child1.start && child2.start && child1?.start < child2.start) return -1
    return 0
  })

  // Add sorted children list to the timelineItems
  timelineItems.splice(timelineItems.length, 0, ...children)

  // Make sure that all items have an end.
  timelineItems.forEach((item) => {
    if (!item.end) item.end = item.start
  })

  return timelineItems.length === 0 ? undefined : timelineItems
}

export function hasValidYear(person: Person): boolean {
  const hasBCBirth = person.birthDate && person.birthDate.charAt(0) === '-'
  const hasBCDeath = person.deathDate && person.deathDate.charAt(0) === '-'

  return !(hasBCBirth || hasBCDeath)
}

export function getStartEndYears(person: Person): StartEndDate {
  const birthData = person?.birthDate?.split('-')
  const deathData = person?.deathDate?.split('-')
  const start = birthData?.[0]
  const end = deathData?.[0]

  return {
    start: start ? parseInt(start, 10) : undefined,
    end: end ? parseInt(end, 10) : undefined,
  }
}
