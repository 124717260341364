import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { usePageView } from '@fs/zion-analytics'
import Head, { Title } from '@fs/zion-head'
import {
  AppLayout,
  colors,
  Divider,
  LayoutBand,
  List,
  ListItem,
  Separator,
  Paragraph,
  Skeleton,
  DataPreview,
  H3,
} from '@fs/zion-ui'
import useSurnameIndex, { useSurnameIndexStatus } from '../../services/surname-index-service'
import { getPathFromUrl } from '../../services/url-service'
import NotFoundError from '../errors/NotFoundError'
import ServerError from '../errors/ServerError'

const itemCss = css`
  color: ${colors.blue50};
`

const keyMap = {
  armn: 'ancestors.language.armn.label',
  cyrl: 'ancestors.language.cyrl.label',
  deva: 'ancestors.language.deva.label',
  grek: 'ancestors.language.grek.label',
  hang: 'ancestors.language.hang.label',
  hani: 'ancestors.language.hani.label',
  hrkt: 'ancestors.language.hrkt.label',
  khmr: 'ancestors.language.khmr.label',
  laoo: 'ancestors.language.laoo.label',
  latn: 'ancestors.language.latn.label',
  sinh: 'ancestors.language.sinh.label',
  thai: 'ancestors.language.thai.label',
}

export default function ScriptsPage(): JSX.Element {
  const [t] = useTranslation()
  const dataState = useSurnameIndexStatus()
  const data = useSurnameIndex()
  usePageView({ page_detail: 'Surname Index: Scripts' })

  if (dataState.notFound) return <NotFoundError />

  if (dataState.serverError) return <ServerError />

  if (dataState.error) throw dataState.error // Let ErrorBoundary catch this error

  return (
    <AppLayout fullWidth backgroundColor={colors.gray00}>
      <Title>{t('ancestors.surname-index.meta-data.title')}</Title>
      <Head>
        <meta name="robots" content="noindex, follow" />
      </Head>
      <LayoutBand minHeight={700}>
        <Separator size="lg" />

        <H3 size="H5">{t('ancestors.select-script.header')}</H3>
        <Paragraph>{t('ancestors.select-script.description')}</Paragraph>
        <Divider />
        <Separator size="xxs" />

        <DataPreview.Loading loading={dataState.loading}>
          <div style={{ marginLeft: 16 }} data-testid="scripts-loading">
            {Array.from({ length: 6 }).map(() => (
              <Skeleton.Paragraph key={Math.random()} maxWidth={Math.random() * 25 + 50} size="sm" />
            ))}
          </div>
        </DataPreview.Loading>

        <DataPreview.Ready loading={dataState.loading}>
          <List>
            {data?.scripts &&
              data.scripts.map((item) => {
                return (
                  <ListItem
                    data-testid="scripts-item"
                    dense
                    primaryText={<span css={itemCss}>{t(keyMap[item.bucketName])}</span>}
                    to={getPathFromUrl(item.url, true)}
                    linkName="Scripts: Language"
                    key={item.bucketName}
                    onClick={() => {
                      window?.scrollTo?.(0, 0)
                    }}
                  />
                )
              })}
          </List>
        </DataPreview.Ready>
      </LayoutBand>
    </AppLayout>
  )
}
