import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { Paragraph, H2 } from '@fs/zion-ui'
import Head, { Title } from '@fs/zion-head'
import PersonNotFound from './PersonNotFound.svg'
import PersonBanner from '../person-banner/PersonBanner'

const imageCss = css`
  background-image: url(${PersonNotFound});
  background-color: #c4c4c4;
  background-position: center bottom;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100vh;
`
const errorCss = css`
  text-align: center;
  margin-top: 50px;
`
type Props = {
  personId?: string
  CF?: boolean
}
export default function GoneError({ personId, CF }: Props): JSX.Element {
  const [t] = useTranslation()
  const heading = CF ? t('ancestors.error-cf.header') : t('ancestors.gone-error.header')
  return (
    <div css={imageCss}>
      <Head>
        <meta httpEquiv="status" content="410 Gone" />
      </Head>
      <Title>{`${t('ancestors.error-page.meta-data.title')} | ${t('ancestors.gone-error.header')}`}</Title>
      <PersonBanner
        person={
          personId
            ? { id: personId, gender: 'UNKNOWN', url: '', name: t('ancestors.private-information.label') }
            : undefined
        }
        CF
      />
      <div data-testid="gone-error" css={errorCss}>
        <H2 centered size="H3">
          {heading}
        </H2>
        {!CF && <Paragraph centered>{t('ancestors.gone-error.text')}</Paragraph>}
      </div>
    </div>
  )
}
