import React from 'react'
import { LayoutBand, Separator, colors, useContainerWidth, Row, MarginBox } from '@fs/zion-ui'
import { Person } from '@fs/zion-person'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { useParams } from '@fs/zion-router'
import { appName } from '@fs/zion-config'
import { SocialMenu, SocialBar } from '@fs/zion-social'
import type { Person as PersonType } from '../../types/persons'

const stripeColorMap = {
  MALE: colors.blue80,
  FEMALE: colors.red80,
  UNKNOWN: colors.gray80,
}

const colorStripeCss = css`
  height: 8px;
`

const removeTuckInSize = css`
  & div button {
    margin: 0;
  }
`

const allowedApps = ['facebook', 'twitter', 'whatsApp', 'email', 'copyLink']
const analytics = { link_app: appName, link_page: 'Person: Header' }

type Props = {
  person?: PersonType
  displaySocial?: boolean
  CF?: boolean
}

export default function PersonBanner({ person, displaySocial, CF }: Props): JSX.Element {
  const [t] = useTranslation()
  const { pid } = useParams()
  const isDesktop = !!useContainerWidth()({ lg: true })
  return (
    <h1
      style={{
        /* Removes horizontal scrollbar. See https://familysearch.slack.com/archives/CMLL2NGUF/p1680197930194719 */
        overflow: 'hidden',
      }}
    >
      <div css={colorStripeCss} style={{ background: stripeColorMap[person?.gender ?? 'UNKNOWN'] }} />
      <LayoutBand maxWidth="lg" marginY="xxs" color={colors.gray00}>
        <div style={{ position: 'relative' }}>
          {displaySocial && person && <Separator size={isDesktop ? 'nano' : 'md'} />}

          <Person
            personObj={
              person || {
                name: t('ancestors.unknown.label'),
                id: pid,
                unknown: true,
              }
            }
            componentSize="xl"
            lifespanSize="long"
            doNotLinkToPersonPage
            hidePid={CF}
          />

          {displaySocial && !isDesktop && person && (
            <>
              <Separator size="md" />
              <ShareButton name={person.name} data-testid="person-banner-share-button" />
            </>
          )}
        </div>
        {isDesktop && displaySocial && person && (
          <div css={removeTuckInSize}>
            <Row>
              <div growX />

              <SocialBar
                sharable={{
                  url: window.location.href,
                  subject: t('ancestors.social-media-banner.share-subject', { name: person.name }),
                }}
                useIconButtons
                color="gray"
                apps={allowedApps}
                analytics={analytics}
                gutters="sm"
              />
            </Row>
          </div>
        )}
      </LayoutBand>
    </h1>
  )
}

const shareCss = css`
  position: absolute;
  top: calc(var(--margin-box-y) * -1 + 4px);
  right: calc(var(--margin-box-x) * -1 + 8px);
`

function ShareButton({ name }): JSX.Element {
  const [t] = useTranslation()

  const sharable = {
    url: window.location.href,
    subject: t('ancestors.social-media-banner.share-subject', { name }),
  }

  return (
    <div css={shareCss} data-testid="share-button">
      <MarginBox marginX="sm">
        <SocialMenu sharable={sharable} inline={false} buttonEmphasis="low" apps={allowedApps} analytics={analytics} />
      </MarginBox>
    </div>
  )
}
