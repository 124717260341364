import React, { lazy } from 'react'
import ErrorBoundary from '@fs/zion-error-boundary'
import { Switch, Route, NotFound } from '@fs/zion-router'
import { pidRegexStr } from '@fs/zion-pid'
import AncestorPage from './components/AncestorPage/AncestorPage'
import HomePage from './components/HomePage/HomePage'
import SearchPage from './components/search/SearchPage'
import IndexPage from './components/surnames/IndexPage'
import ScriptsPage from './components/surnames/ScriptsPage'
import SavedHtmlError from './components/errors/SavedHtmlError'
import { DataContext } from './services/data-service'
import { DefaultScriptsContext } from './services/default-scripts-service'
import { SurnameIndexContext } from './services/surname-index-service'
import { getExternalBaseUrl } from './services/url-service'
import { appSupportedLocales } from './types/appSupportedLocales'

const DevelopmentPage = lazy(() => import('./DevelopmentPage'))
const SCRIPT_MATCH = ':script([a-z]{4})'
const PID_MATCH = `:pid(${pidRegexStr})`
const SURNAMES_MATCH = ':surnames(surnames)'
const LOCALE_MATCH = `:locale(${appSupportedLocales.join('|')})`
const CACHE_BUST_MATCH = ':cb(cb[0-9]{13})'

export const ANCESTOR_PAGE_PATHS = [
  `/${PID_MATCH}/:nameAndLifespan`,
  `/${PID_MATCH}/:nameAndLifespan/${CACHE_BUST_MATCH}`,
  `/${LOCALE_MATCH}/${PID_MATCH}/:nameAndLifespan`,
  `/${LOCALE_MATCH}/${PID_MATCH}/:nameAndLifespan/${CACHE_BUST_MATCH}`,
  `/tree/ancestors/${PID_MATCH}/:nameAndLifespan`,
  `/tree/ancestors/${PID_MATCH}/:nameAndLifespan/${CACHE_BUST_MATCH}`,
  `/tree/ancestors/${LOCALE_MATCH}/${PID_MATCH}/:nameAndLifespan`,
  `/tree/ancestors/${LOCALE_MATCH}/${PID_MATCH}/:nameAndLifespan/${CACHE_BUST_MATCH}`,
]

function DomainRedirect(): null {
  window.location.href = `${getExternalBaseUrl()}${window.location.pathname}`
  return null
}

function App(props): JSX.Element {
  return (
    <ErrorBoundary>
      <Switch>
        {process.env.NODE_ENV !== 'production' && <Route exact path="/dev" component={DevelopmentPage} />}

        <Route
          exact
          path={[
            '/',
            `/${CACHE_BUST_MATCH}`,
            `/${LOCALE_MATCH}`,
            `/${LOCALE_MATCH}/${CACHE_BUST_MATCH}`,
            '/tree/ancestors/',
            `/tree/ancestors/${CACHE_BUST_MATCH}`,
            `/tree/ancestors/${LOCALE_MATCH}`,
            `/tree/ancestors/${LOCALE_MATCH}/${CACHE_BUST_MATCH}`,
          ]}
        >
          <DefaultScriptsContext {...props}>
            <HomePage />
          </DefaultScriptsContext>
        </Route>

        <Route
          exact
          path={[
            '/search',
            `/search/${CACHE_BUST_MATCH}`,
            `/${LOCALE_MATCH}/search`,
            `/${LOCALE_MATCH}/search/${CACHE_BUST_MATCH}`,
            '/tree/ancestors/search',
            `/tree/ancestors/search/${CACHE_BUST_MATCH}`,
            `/tree/ancestors/${LOCALE_MATCH}/search`,
            `/tree/ancestors/${LOCALE_MATCH}/search/${CACHE_BUST_MATCH}`,
          ]}
        >
          <SearchPage />
        </Route>

        <Route
          exact
          path={[
            `/${SURNAMES_MATCH}`,
            `/${SURNAMES_MATCH}/${CACHE_BUST_MATCH}`,
            `/${LOCALE_MATCH}/${SURNAMES_MATCH}`,
            `/${LOCALE_MATCH}/${SURNAMES_MATCH}/${CACHE_BUST_MATCH}`,
            `/tree/ancestors/${SURNAMES_MATCH}`,
            `/tree/ancestors/${SURNAMES_MATCH}/${CACHE_BUST_MATCH}`,
            `/tree/ancestors/${LOCALE_MATCH}/${SURNAMES_MATCH}`,
            `/tree/ancestors/${LOCALE_MATCH}/${SURNAMES_MATCH}/${CACHE_BUST_MATCH}`,
          ]}
        >
          <SurnameIndexContext {...props}>
            <ScriptsPage />
          </SurnameIndexContext>
        </Route>

        <Route
          exact
          path={[
            `/${SURNAMES_MATCH}/${SCRIPT_MATCH}`,
            `/${SURNAMES_MATCH}/${SCRIPT_MATCH}/${CACHE_BUST_MATCH}`,
            `/${LOCALE_MATCH}/${SURNAMES_MATCH}/${SCRIPT_MATCH}`,
            `/${LOCALE_MATCH}/${SURNAMES_MATCH}/${SCRIPT_MATCH}/${CACHE_BUST_MATCH}`,
            `/tree/ancestors/${SURNAMES_MATCH}/${SCRIPT_MATCH}`,
            `/tree/ancestors/${SURNAMES_MATCH}/${SCRIPT_MATCH}/${CACHE_BUST_MATCH}`,
            `/tree/ancestors/${LOCALE_MATCH}/${SURNAMES_MATCH}/${SCRIPT_MATCH}`,
            `/tree/ancestors/${LOCALE_MATCH}/${SURNAMES_MATCH}/${SCRIPT_MATCH}/${CACHE_BUST_MATCH}`,
          ]}
        >
          <SurnameIndexContext {...props}>
            <IndexPage />
          </SurnameIndexContext>
        </Route>

        <Route
          exact
          path={[
            `/${SURNAMES_MATCH}/${SCRIPT_MATCH}/*`,
            `/${SURNAMES_MATCH}/${SCRIPT_MATCH}/*/${CACHE_BUST_MATCH}`,
            `/${LOCALE_MATCH}/${SURNAMES_MATCH}/${SCRIPT_MATCH}/*`,
            `/${LOCALE_MATCH}/${SURNAMES_MATCH}/${SCRIPT_MATCH}/*/${CACHE_BUST_MATCH}`,
            `/tree/ancestors/${SURNAMES_MATCH}/${SCRIPT_MATCH}/*`,
            `/tree/ancestors/${SURNAMES_MATCH}/${SCRIPT_MATCH}/*/${CACHE_BUST_MATCH}`,
            `/tree/ancestors/${LOCALE_MATCH}/${SURNAMES_MATCH}/${SCRIPT_MATCH}/*`,
            `/tree/ancestors/${LOCALE_MATCH}/${SURNAMES_MATCH}/${SCRIPT_MATCH}/*/${CACHE_BUST_MATCH}`,
          ]}
        >
          <SurnameIndexContext {...props}>
            <IndexPage />
          </SurnameIndexContext>
        </Route>

        <Route
          exact
          path={[
            `/${PID_MATCH}`,
            `/${PID_MATCH}/${CACHE_BUST_MATCH}`,
            `/${LOCALE_MATCH}/${PID_MATCH}`,
            `/${LOCALE_MATCH}/${PID_MATCH}/${CACHE_BUST_MATCH}`,
            `/tree/ancestors/${PID_MATCH}`,
            `/tree/ancestors/${PID_MATCH}/${CACHE_BUST_MATCH}`,
            `/tree/ancestors/${LOCALE_MATCH}/${PID_MATCH}`,
            `/tree/ancestors/${LOCALE_MATCH}/${PID_MATCH}/${CACHE_BUST_MATCH}`,
          ]}
        >
          <DataContext {...props}>
            <AncestorPage />
          </DataContext>
        </Route>

        <Route exact path={ANCESTOR_PAGE_PATHS}>
          <DataContext {...props}>
            <AncestorPage />
          </DataContext>
        </Route>
        <Route
          path={[
            '*/Desktop/*',
            '*/Users/*',
            '*/USUARIOS/*',
            '*/OneDrive/*',
            '*/Museum/*',
            '*/Personal/*',
            '*/Pictures/*',
          ]}
        >
          <DataContext {...props}>
            <SavedHtmlError />
          </DataContext>
        </Route>

        <Route path={['/tree/person/*', '/tree/pedigree/*']}>
          <DomainRedirect />
        </Route>

        <Route path="*" component={NotFound} />
      </Switch>
    </ErrorBoundary>
  )
}

export default App
