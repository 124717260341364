import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { Paragraph, H2 } from '@fs/zion-ui'
import { Title } from '@fs/zion-head'
import PersonNotFound from './PersonNotFound.svg'
import PersonBanner from '../person-banner/PersonBanner'

const imageCss = css`
  background-image: url(${PersonNotFound});
  background-color: #c4c4c4;
  background-position: center bottom;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100vh;
`
const errorCss = css`
  text-align: center;
  margin-top: 50px;
`

type Props = {
  personId?: string
}
export default function NotFoundError({ personId }: Props): JSX.Element {
  const [t] = useTranslation()

  return (
    <div css={imageCss}>
      <Title>{`${t('ancestors.page.tab.title')} | ${t('ancestors.not-found-error.header')}`}</Title>
      <PersonBanner
        person={
          personId
            ? { id: personId, name: t('ancestors.private-information.label'), url: '', gender: 'UNKNOWN' }
            : undefined
        }
        CF
      />
      <div data-testid="not-found-error" css={errorCss}>
        <H2 centered size="H3">
          {t('ancestors.not-found-error.header')}
        </H2>
        <Paragraph centered>{t('ancestors.not-found-error.text')}</Paragraph>
      </div>
    </div>
  )
}
