import React, { useContext, useEffect, useState } from 'react'
import axios from '@fs/zion-axios'
import type { DefaultScripts } from '../types/data-types'
import readDefaultScripts from './default-scripts-reader'

type Store = {
  data?: DefaultScripts
  error?: Error
  loading: boolean
  notFound: boolean
  serverError: boolean
}

const PrivateContext = React.createContext<Store>(initStore())

export default function useDefaultScripts(): DefaultScripts | undefined {
  const store = useContext(PrivateContext)
  if (!store) throw new Error('useDefaultScripts must be used within a DefaultScriptsContext')
  return store.data
}

export function useDefaultScriptsStatus(): Store {
  return useContext(PrivateContext)
}

function initStore(data?: DefaultScripts, error?: unknown): Store {
  const loading = !data && !error
  const axiosError = axios.isAxiosError(error) && error
  const notFound = axiosError && error?.response?.status === 404
  const serverError = axiosError && error?.response?.status === 500
  return { data, error: axiosError || undefined, loading, notFound, serverError }
}

export type DataProps = {
  data?: DefaultScripts
  children?: React.ReactElement
}

export function DefaultScriptsContext({ data, children }: DataProps): JSX.Element {
  const [store, setStore] = useState(initStore(data))

  useEffect(() => {
    let cancelled = false
    async function read(): Promise<void> {
      let d: DefaultScripts | undefined
      let e: unknown | undefined
      try {
        d = await readDefaultScripts()
      } catch (error) {
        if (!cancelled) {
          e = error
        }
      }
      if (!cancelled && d?.error) {
        e = d.error
      }
      setStore(initStore(d, e))
    }
    if (!store.data && !store.error) {
      read()
    }
    return () => {
      cancelled = true
    }
  }, [store])

  return <PrivateContext.Provider value={store}>{children}</PrivateContext.Provider>
}
