import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from '@fs/zion-router'
import type { AxiosError } from '@fs/zion-axios'
import axios from '@fs/zion-axios'
import type { TfSource } from '@fs/zion-tree-types'
import readData from './s3data-reader'
import type { AncestorData } from '../types/data-types'
import type { CustomWindow } from '../types/window'

type Store = {
  data?: AncestorData
  error?: Error
  pid?: string
  loading: boolean
  notFound: boolean
  gone: boolean
  movedTo?: string
  CF?: boolean
}

type DataContext = {
  store: Store
  setStore?: React.Dispatch<React.SetStateAction<Store>>
}

const PrivateContext = React.createContext<DataContext>({ store: initStore() })

export default function useData(): AncestorData | undefined {
  const { store } = useContext(PrivateContext)

  if (!store) throw new Error('useData must be used within a DataContext')
  return store.data
}

export function useDataContext(): DataContext {
  return useContext(PrivateContext)
}

function initStore(pid?: string, data?: AncestorData, error?: unknown): Store {
  const loading = !data && !error
  const axiosError = axios.isAxiosError(error) && error
  const notFound = axiosError && error?.response?.status === 404
  const gone = axiosError && error?.response?.status === 410
  const movedTo = axiosError && error?.response?.status === 301 ? error?.response?.headers.location : undefined
  const win = window as CustomWindow

  return {
    pid,
    data,
    error: axiosError || undefined,
    loading,
    notFound,
    gone,
    movedTo,
    CF: win?.SERVER_DATA?.ANCESTORS?.CF || false,
  }
}

const genericAxios = axios.create()

async function updateSourcePublicImages(sources: TfSource[]): Promise<void> {
  const promises: Promise<void>[] = []
  sources.forEach((source) => {
    // In the SourcesCard we only display the sourceThumbnailLink to reduce duplicate calls they should match
    const imageLink = source.sourceThumbnailLink

    if (imageLink) {
      promises.push(
        genericAxios
          .head(imageLink)
          .then(() => {
            source.imagePublic = true
            source.thumbnailPublic = true
            return undefined
          })
          .catch(() => {
            source.imagePublic = false
            source.thumbnailPublic = false
          })
      )
    }
  })

  await Promise.all(promises)
}

export type DataProps = {
  pid?: string
  data?: AncestorData
  error?: AxiosError
  children?: React.ReactElement
}
export function DataContext({ pid, data, error, children }: DataProps): JSX.Element {
  const [store, setStore] = useState(initStore(pid, data, error))
  const params = useParams()
  if (!pid) pid = params.pid

  useEffect(() => {
    let cancelled = false
    async function read(id: string): Promise<void> {
      let d: AncestorData | undefined
      let e: unknown | undefined
      try {
        d = await readData(id)
      } catch (err) {
        e = err
      }
      try {
        if (d?.sources?.sources) {
          await updateSourcePublicImages(d.sources?.sources || [])
        }
      } catch (err) {
        e = err
      }
      if (!cancelled) {
        setStore(initStore(id, d, e))
      }
    }
    if (!cancelled && pid && !store.data && !store.error) {
      read(pid)
    }

    return () => {
      cancelled = true
    }
  }, [store, pid])
  const value: DataContext = useMemo(() => ({ store, setStore }), [store])

  return <PrivateContext.Provider value={value}>{children}</PrivateContext.Provider>
}
