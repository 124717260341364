export const treeDataBaseUrl = `/service/tree/tree-data`

export function getPathFromUrl(url: string | undefined, withoutLang?: boolean | undefined): string {
  // Get path of the url after protocol, host, and port
  let parts = (url || '').split('://')
  let path = parts[parts.length === 1 ? 0 : 1]
  path = parts.length === 1 ? path : path.substring(path.indexOf('/') + 1)

  if (withoutLang) {
    // Remove lang (2 characters) from the front of the path
    parts = path.split('/')
    const lang = parts.length > 2 ? parts[1] : ''
    if (lang.length === 2) {
      parts.splice(1, 1)
    }
    path = parts.join('/')
  }

  return path
}

export function getExternalBaseUrl(): string {
  const hostName = window?.location?.hostname || ''

  if (hostName.includes('integ') || hostName.includes('localhost')) return 'https://integration.familysearch.org'
  if (hostName.includes('beta')) return 'https://beta.familysearch.org'
  if (hostName.includes('ancestors.')) return 'https://www.familysearch.org'

  return ''
}
