import React, { useCallback, useEffect, useRef, useState } from 'react'
import { css } from '@emotion/core'
import { usePageView, trackEvent } from '@fs/zion-analytics'
import { Title } from '@fs/zion-head'
import { Paragraph, Button, DataPreview, LayoutBand, H1, MarginBox, H2, Separator } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { AncestorCard, SkeletonAncestorCard } from '@fs/zion-ancestor-card'
import AdvancedSearchCard from './AdvancedSearchCard'
import Search from './Search'
import SearchError from './SearchError'
import { doSearch, getCachedSearchParams, setCachedSearchParams, getTreeFindUrl } from '../../services/search-service'
import type { AncestorSearch, SearchParams } from '../../types/data-types'
import NoResultsSvg from './NoResults.svg'

const noResultsCss = css`
  margin: 50px auto;
  text-align: center;
`

const subtextCss = css`
  margin: 0 auto;
  max-width: 500px;
  text-align: center;
`

const cardCss = css`
  &:hover {
    cursor: pointer;
  }
`

const resultsCss = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  gap: 12px;
  padding: 0;
  & > li {
    margin: 0;
    padding: 0;
  }
`
const linkCss = css`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`

const skeletonCards = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]

export default function SearchPage(): JSX.Element {
  const [t] = useTranslation()
  const [treeFindUrl, setTreeFindUrl] = useState('')
  const initialParams: SearchParams = getCachedSearchParams()
  const searchOnLoad = useRef(false)
  const initialSearch: AncestorSearch = { params: initialParams }
  const [search, setSearch] = useState(initialSearch)
  const [loading, setLoading] = useState(false)
  const [searchError, setSearchError] = useState(false)
  const isCurrent = useRef(true)

  useEffect(() => {
    isCurrent.current = true
    return () => {
      isCurrent.current = false
    }
  })

  usePageView({ page_detail: 'Search' })
  getTreeFindUrl(search?.params)
    .then((url) => {
      setTreeFindUrl(url)
      return undefined
    })
    .catch(console.error)

  const onSubmit = useCallback((params: SearchParams, startIndex?: number): void => {
    setCachedSearchParams(params)
    setLoading(true)
    doSearch(params, startIndex)
      .then((results) => {
        if (isCurrent.current) {
          setSearchError(!!results.error)
          setLoading(false)
          setSearch(results)
        }
        return undefined
      })
      .catch(console.error)
  }, [])

  useEffect(() => {
    let cancelled = false
    if (!searchOnLoad.current && !cancelled && !loading && !search?.records && initialParams?.self?.surname?.value) {
      searchOnLoad.current = true
      onSubmit(initialParams, 0)
    }

    return () => {
      cancelled = true
    }
  }, [initialParams, loading, onSubmit, search?.records])

  return (
    <>
      <Title>{t('ancestors.surname-index.meta-data.title')}</Title>
      <LayoutBand maxWidth="md">
        <Search
          HeadingComponent={H1}
          onSearchPage
          params={initialParams}
          onSubmit={onSubmit}
          header={t('ancestors.search-for-ancestor.header')}
          showMore
          data-testid="search-page-form"
        />

        {searchError && <SearchError />}
      </LayoutBand>

      <LayoutBand maxWidth="lg">
        <DataPreview.Provider loading={loading}>
          <DataPreview.Loading>
            <MarginBox marginY="xs">
              <ul css={resultsCss}>
                {skeletonCards &&
                  skeletonCards.map((card, index) => {
                    const key = `skeleton-${index}`
                    return (
                      <li key={key}>
                        <SkeletonAncestorCard hidePid />
                      </li>
                    )
                  })}
              </ul>
            </MarginBox>
          </DataPreview.Loading>
          <DataPreview.Ready>
            <MarginBox marginY="xs">
              {search?.records?.length !== 0 && (
                <ul css={resultsCss}>
                  {search?.records &&
                    search.records.map((ancestorData) => {
                      return (
                        <li
                          css={cardCss}
                          key={ancestorData.person.identifier}
                          data-testid={`search-result-${ancestorData.person.identifier}`}
                          aria-label={t('ancestors.person.label')}
                        >
                          <a
                            data-testid="search-link"
                            href={ancestorData.person.url}
                            onClick={() => {
                              trackEvent({ link_name: 'Person' })
                            }}
                            css={linkCss}
                            aria-label={ancestorData.person.name}
                          >
                            <AncestorCard as="h2" ancestorData={ancestorData} hidePid />
                          </a>
                        </li>
                      )
                    })}

                  {search?.records && (
                    <li css={cardCss} key="advanced-search-help" aria-label={t('ancestors.advanced-search-card.label')}>
                      <AdvancedSearchCard as="h2" treeFindUrl={treeFindUrl} />
                    </li>
                  )}
                </ul>
              )}
              {search?.records?.length === 0 && (
                <div css={noResultsCss}>
                  <img src={NoResultsSvg} alt={t('ancestors.search-not-found.header')} />
                  <Separator size="md" />
                  <div>
                    <H2 size="H4" centered>
                      {t('ancestors.search-not-found.header')}
                    </H2>
                    <div css={subtextCss}>
                      <Paragraph>{t('ancestors.seach-not-found.text')}</Paragraph>
                    </div>
                    <Button
                      data-testid="family-tree-search-button"
                      linkName="Family Tree Search"
                      onClick={async () => {
                        if (window?.location?.href) {
                          window.location.href = treeFindUrl
                        }
                      }}
                    >
                      {t('ancestors.family-tree-search.action')}
                    </Button>
                  </div>
                </div>
              )}
            </MarginBox>
          </DataPreview.Ready>
        </DataPreview.Provider>
      </LayoutBand>
    </>
  )
}
