import React from 'react'
import { useContainerWidth, Row, H2, Separator } from '@fs/zion-ui'
import { SocialBar, SocialMenu } from '@fs/zion-social'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import type { Person } from '../../types/persons'

const mediaBannerCss = css`
  text-align: center;
`

const apps = ['facebook', 'twitter', 'whatsApp']

type ShareButtonProps = {
  name: string
  analytics: Record<string, string>
}

export function ShareButton({ name, analytics }: ShareButtonProps): JSX.Element {
  const [t] = useTranslation()

  const sharable = {
    url: window.location.href,
    subject: t('ancestors.social-media-banner.share-subject', { name }),
  }

  return <SocialMenu sharable={sharable} platforms={apps} analytics={analytics} inline={false} buttonEmphasis="low" />
}

export default function AncestorSocial({ person }: { person: Person }): JSX.Element {
  const [t] = useTranslation()
  const atWidth = useContainerWidth()

  const isMobile = atWidth({ lg: false, default: true })
  const { name } = person
  const sharable = {
    url: window.location.href,
    subject: t('ancestors.social-media-banner.share-subject', {
      name,
    }),
  }

  // Social stuff needs analytics to work properly
  const analytics = { link_app: 'tree-ancestors-r9', link_page: 'Person: Footer' }

  return (
    <div css={mediaBannerCss}>
      <H2 size="H5" centered>
        {t('ancestors.social-media-banner.header')}
      </H2>
      <Separator size="nano" />

      {!isMobile && (
        <Row alignX="center">
          <SocialBar sharable={sharable} platforms={apps} analytics={analytics} />
        </Row>
      )}
      {isMobile && <ShareButton name={name} analytics={analytics} data-testid="social-media-share-button" />}
    </div>
  )
}
