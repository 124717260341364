import type { LocalizedData, TfPerson } from '@fs/zion-tree-types'
import { convertEvent } from './convert-events'
import type { Person } from '../types/persons'
import type { AncestorData } from '../types/data-types'
import convertNameForm from './convert-name-form'

function validateLifespan(lifespan): string | undefined {
  if (lifespan === '-' || lifespan === '') {
    return undefined
  }

  return lifespan
}

export default function convertPerson(s3Person: TfPerson, localizedData: LocalizedData = {}): Person {
  const {
    identifier,
    gender,
    nameForms,
    portrait,
    name,
    fullLifespan,
    lifespan,
    birthDate,
    deathDate,
    url,
    published,
    datePublished,
    ageAtDeath,
  } = s3Person
  const newNameForms = nameForms?.map((nameForm) => convertNameForm(nameForm))
  // eslint-disable-next-line no-template-curly-in-string -- Tis what we want
  let customPersonLink = datePublished || published ? s3Person?.url?.replace(s3Person?.identifier, '${pid}') : undefined
  // Convert absolute URL to relative URL
  if (customPersonLink?.includes('://')) {
    customPersonLink = customPersonLink.substring(customPersonLink.indexOf('/', customPersonLink.indexOf('://') + 3))
  }

  return {
    ageAtDeath,
    name: name ?? '',
    birthDate,
    deathDate,
    lifespan: validateLifespan(localizedData[lifespan || ''] || lifespan),
    fullLifespan: validateLifespan(localizedData[fullLifespan || ''] || lifespan),
    id: identifier,
    gender: gender.toUpperCase() as AncestorData['person']['gender'],
    birth: convertEvent('BIRTH', s3Person, localizedData),
    death: convertEvent('DEATH', s3Person, localizedData),
    christening: convertEvent('CHRISTENING', s3Person, localizedData),
    burial: convertEvent('BURIAL', s3Person, localizedData),
    portraitUrl: portrait?.url,
    nameConclusion: {
      type: 'NAME',
      details: {
        fullText: name ?? '',
        nameForms: newNameForms || [],
      },
    },
    givenName: newNameForms?.[0].givenPart,
    familyName: newNameForms?.[0].familyPart,
    url,
    datePublished,
    customPersonLink,
  }
}
