import useData from './data-service'
import type { NameMeaning } from '../types/data-types'

/**
 * Returns the name meaning sections of the page's person data.
 */
export default function useNameMeanings(): NameMeaning[] | undefined {
  const data = useData()
  return data?.nameMeanings
}
