import axios from '@fs/zion-axios'
import { i18n } from '@fs/zion-locale'
import type { SurnameIndexData } from '../types/data-types'
import { treeDataBaseUrl, getPathFromUrl } from './url-service'

export default async function readData(url: string): Promise<SurnameIndexData> {
  const endpointUrl = `${treeDataBaseUrl}/published${getPathFromUrl(url, true)}`
  // const endpointUrl = 'https://httpbin.org/status/500'
  try {
    const response = await axios.get(endpointUrl, {
      headers: {
        'x-familysearch-locale': i18n.language,
      },
    })
    const data: SurnameIndexData = url.endsWith('/surnames') ? { scripts: response.data } : response.data
    const parts = url.split('/')
    const prefix = parts.slice(0, parts.length - 1).join('/')
    let foundCurrent = false
    data?.navigation?.forEach((item) => {
      if (!foundCurrent && item.url.includes(prefix)) {
        item.current = true
        foundCurrent = true
      }
    })
    return data
  } catch (error) {
    console.error(`Error reading ${endpointUrl}`, error)
    throw error
  }
  return {}
}
