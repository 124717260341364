import type {
  Address,
  Breadcrumb,
  ImageObject,
  Logo,
  Location,
  Organization,
  Page,
  Person,
  Place,
} from '../types/schema-org'
import type { Person as AncestorPerson } from '../types/persons'
import type { EventDate, EventPlace } from '../types/conclusions'
import type { Families } from '../types/data-types'

export function getSchemaOrgPerson(person: AncestorPerson, families?: Families | undefined): Person {
  let gender = person?.gender.toLowerCase() || 'Unknown'
  gender = gender.substring(0, 1).toUpperCase() + gender.substring(1)
  const schemaOrgPerson: Person = {
    '@type': 'Person',
    '@context': 'http://schema.org',
    identifier: person.id,
    url: person.url,
    gender,
    name: person.name,
  }

  if (person.birth?.details?.date) {
    schemaOrgPerson.birthDate = getDate(person.birth?.details?.date)
  }

  if (person?.birth?.details?.place) {
    schemaOrgPerson.birthPlace = getPlace(person?.birth?.details?.place)
  }
  if (person.death?.details?.date) {
    schemaOrgPerson.deathDate = getDate(person.death?.details?.date)
  }
  if (person?.death?.details?.place) {
    schemaOrgPerson.deathPlace = getPlace(person?.death?.details?.place)
  }

  if (person?.portraitUrl) {
    schemaOrgPerson.image = [getImageObject(person.portraitUrl, person.name)]
  }

  if (families) {
    const parents = getParents(families)
    if (parents) {
      schemaOrgPerson.parent = parents
    }
    const siblings = getSiblings(person, families)
    if (siblings) {
      schemaOrgPerson.sibling = siblings
    }
    const spouses = getSpouses(person, families)
    if (spouses) {
      schemaOrgPerson.spouse = spouses
    }
    const children = getChildren(families)
    if (children) {
      schemaOrgPerson.children = children
    }
  }

  return schemaOrgPerson
}

function getParents(families: Families | undefined): Person[] | undefined {
  if (!families?.parents) return undefined
  const parents: Person[] = []
  families.parents.forEach((couple) => {
    const { parent1, parent2 } = couple
    if (parent1) {
      parents.push(getSchemaOrgPerson(parent1))
    }
    if (parent2) {
      parents.push(getSchemaOrgPerson(parent2))
    }
  })
  return parents
}

function getSiblings(person: AncestorPerson, families: Families | undefined): Person[] | undefined {
  if (!families?.parents) return undefined
  const { children } = families?.parents[0] || {}
  const siblings: Person[] | undefined = children ? [] : undefined

  if (children) {
    children.forEach((child) => {
      if (child.id !== person.id) {
        siblings?.push(getSchemaOrgPerson(child))
      }
    })
  }

  return siblings
}

function getSpouses(person: AncestorPerson, families: Families | undefined): Person[] | undefined {
  if (!families?.spouses) return undefined
  const spouses: Person[] = []
  families.spouses.forEach((parents) => {
    const { parent1, parent2 } = parents
    if (parent1 && parent1.id !== person.id) {
      spouses?.push(getSchemaOrgPerson(parent1))
    }
    if (parent2 && parent2.id !== person.id) {
      spouses?.push(getSchemaOrgPerson(parent2))
    }
  })
  return spouses
}

function getChildren(families: Families | undefined): Person[] | undefined {
  if (!families?.spouses) return undefined
  const children: Person[] = []
  families.spouses.forEach((spouses) => {
    spouses.children?.forEach((child) => {
      children.push(getSchemaOrgPerson(child))
    })
  })
  return children
}

function getDate(date: EventDate | undefined): string | undefined {
  return date?.localizedText || date?.originalText
}

function getPlace(place: EventPlace | undefined): Place | undefined {
  if (!place) return undefined
  return {
    '@type': 'Place',
    address: place.localizedText || place.originalText,
  }
}

export function getSchemaOrgPage(
  lang: string,
  title: string | undefined,
  description: string | undefined,
  person?: AncestorPerson | undefined
): Page {
  const org = getOrganization()
  const page: Page = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: title || 'Ancestors',
    description,
    inLanguage: lang,
    copyrightYear: new Date().getFullYear(),
    breadcrumb: getBreadcrumb(person),
    copyrightHolder: org,
    publisher: org,
  }

  if (person?.portraitUrl) {
    page.primaryImageOfPage = getImageObject(person.portraitUrl, person.name)
  }

  if (person?.datePublished) {
    page.datePublished = person.datePublished
  }

  return page
}

export function getOrganization(): Organization {
  const org: Organization = {
    '@type': 'Organization',
    legalName: 'FamilySearch International',
    url: 'https://www.familysearch.org/',
    sameAs:
      'https://www.facebook.com/familysearch/, https://www.instagram.com/familysearch/, https://www.pinterest.com/familysearch/, https://twitter.com/familysearch, https://www.wikidata.org/wiki/Q3066228, https://en.wikipedia.org/wiki/FamilySearch, https://www.youtube.com/user/familysearch',
    telephone: '+1 (866) 406-1830',
    email: 'support@familysearch.org',
    address: getAddress(),
    location: getLocation(),
    logo: getLogo(),
  }
  return org
}

function getLocation(): Location {
  const location: Location = {
    '@type': 'Place',
    hasMap:
      'https://www.google.com/maps/place/The+Church+of+Jesus+Christ+of+Latter-day+Saints+-+Church+Office+Building/@40.7711411,-111.8914442,17z/data=!4m12!1m6!3m5!1s0x8752f507c72c8039:0xe67a34043a62cb5e!2sThe+Church+of+Jesus+Christ+of+Latter-day+Saints+-+Church+Office+Building!8m2!3d40.7711371!4d-111.8892502!3m4!1s0x8752f507c72c8039:0xe67a34043a62cb5e!8m2!3d40.7711371!4d-111.8892502',
    telephone: '+ 1 (866) 406-1830',
    geo: {
      '@type': 'GeoCoordinates',
      latitude: 40.7712,
      longitude: -111.8893,
    },
    address: getAddress(),
    logo: getLogo(),
  }
  return location
}

function getAddress(): Address {
  return {
    '@type': 'PostalAddress',
    streetAddress: '50 East North Temple',
    addressLocality: 'Salt Lake City',
    addressRegion: 'UT',
    postalCode: '84150',
    addressCountry: {
      '@type': 'Country',
      name: 'United States',
    },
  }
}

function getLogo(): Logo {
  return {
    '@type': 'ImageObject',
    caption: 'FamilySearch logo',
    url: 'https://edge.fscdn.org/assets/components/hf/assets/img/logos/tree-logotype-2x-57dfa10aa24b73764cf6d7f606695d3a.png',
    height: '68 px',
    width: '328 px',
  }
}

export function getBreadcrumb(person: AncestorPerson | undefined): Breadcrumb {
  const breadcrumb: Breadcrumb = {
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        item: {
          '@id': 'https://ancestors.familysearch.org/',
          name: 'Ancestors',
        },
      },
    ],
  }

  if (person) {
    breadcrumb.itemListElement.push({
      '@type': 'ListItem',
      position: 2,
      item: {
        '@id': person.url,
        name: `${person.name} (${person.lifespan})`,
      },
    })
  }

  return breadcrumb
}

export function getImageObject(contentUrl: string, keywords: string): ImageObject {
  const imageObject: ImageObject = {
    '@type': 'ImageObject',
    representativeOfPage: 'http://schema.org/True',
    contentUrl,
    isFamilyFriendly: 'http://schema.org/True',
    keywords,
    license: 'https://www.familysearch.org/terms',
  }

  return imageObject
}
