import React, { Suspense } from 'react'
import type { FC, ReactElement } from 'react'
import ReactDOM from 'react-dom'
import '@fs/react-scripts/polyfills'
import * as Sentry from '@sentry/react'
import type { Flags } from '@fs/zion-flags'
import { FeatureFlagsProvider } from '@fs/zion-flags'
import { setAppLocales, essentialLocales } from '@fs/zion-locale'
import Root from '@fs/zion-root'
import { Router } from '@fs/zion-router'
import { appPath, sentryDSN } from '@fs/zion-config'
import { AppLayout, colors } from '@fs/zion-ui'
import { getSentryConfig } from '@fs/tree-config'
import App from './App'
import * as serviceWorker from './serviceWorker'
import AncestorsHeader from './components/AncestorsHeader/AncestorsHeader'
import AncestorsFooter from './components/AncestorsFooter/AncestorsFooter'
import runQualtricsInterceptIIFE from './qualtrics-intercept'
import './locales'

// Enable local feature flag development using the file "dev.flags.js"
// If that file exports anything truthy it will initialize feature flags in localhost mode
// and use the flags defined in that file, instead of flags defined in split.
const mockFlags = (
  process.env.NODE_ENV === 'development' ? import('./dev.flags').then((m) => m.default) : undefined
) as undefined | Flags

// initialize Sentry for the app
if (sentryDSN) {
  // pass Sentry overrides to mergeSentryConfig
  Sentry.init(
    getSentryConfig({
      ignoreErrors: [],
      sampleRate: 0.05,
    })
  )
}

// For details about loading translations: https://www.familysearch.org/frontier/docs/develop/i18n
setAppLocales(essentialLocales)

// This is where you pass data from the server to the client using the SERVER_DATA global.
// Here we pass the mounted app path from the server to the client.
const base = appPath ? new URL(appPath).pathname : ''

const state = (
  window as Window & typeof globalThis & { SERVER_DATA: { ssrState: { pid?: unknown; initialData?: unknown } } }
).SERVER_DATA.ssrState

const FrontierRoot: FC = (): ReactElement => {
  return (
    <>
      <React.StrictMode>
        <FeatureFlagsProvider mockFlags={mockFlags}>
          <Router basename={base}>
            <Root fullScreen analytics header={{ hidden: true }} footer={{ hidden: true }}>
              <AppLayout fullWidth backgroundColor={colors.gray03}>
                <section style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                  <AncestorsHeader />
                  <App pid={state?.pid} initialData={state?.initialData} />
                </section>
                <AncestorsFooter />
              </AppLayout>
            </Root>
          </Router>
        </FeatureFlagsProvider>
      </React.StrictMode>
      <div id="ZN_cI4OucMtvbHUxSZ" />
    </>
  )
}

if (state) {
  ReactDOM.hydrate(<FrontierRoot />, document.getElementById('root'))
} else {
  ReactDOM.render(
    <Suspense fallback="">
      <FrontierRoot />
    </Suspense>,
    document.getElementById('root')
  )
}

// Inject Qualtrics Site Intercept Website Feedback Code
// NOTE: the page must somewhere have a rendered <div id='ZN_cI4OucMtvbHUxSZ' /> for code injection to work, see above.
runQualtricsInterceptIIFE()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
