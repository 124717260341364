import axios from '@fs/zion-axios'
import { i18n } from '@fs/zion-locale'
import type { AncestorSearch, EventParam, PersonParam, SearchParams } from '../types/data-types'
import { getPathFromUrl, getExternalBaseUrl, treeDataBaseUrl } from './url-service'
import type { CustomWindow } from '../types/window'

const win = window as CustomWindow

export const PAGE_SIZE = 11

export function getCachedSearchParams(): SearchParams {
  const searchFromStorage = win?.sessionStorage.getItem('search')

  return searchFromStorage === null ? {} : JSON.parse(searchFromStorage)
}

export function setCachedSearchParams(params: SearchParams): void {
  win?.sessionStorage.setItem('search', JSON.stringify(params))
}

export function getFormalDate(date: string): Promise<string> {
  return axios
    .get(
      `${treeDataBaseUrl}/authorities/date?term=${encodeURIComponent(date)}&locale=${i18n.language}&sessionId=fakeId`
    )
    .then(({ data }) => {
      return data && data.length > 0 ? data[0].formalDate : ''
    })
    .catch(() => {
      return ''
    })
}

export async function getSearchParams(filters: SearchParams): Promise<URLSearchParams> {
  const params = new URLSearchParams()
  const datePromises: Promise<[string, string]>[] = []
  const getDateParam = (key: string, date: string): Promise<[string, string]> => {
    const paramName = key
    return getFormalDate(date).then((formalDate) => {
      return [paramName, formalDate]
    })
  }

  const likeKeys = {
    birth: 'birthLike',
    christening: 'birthLike',
    death: 'deathLike',
    burial: 'deathLike',
  }

  Object.entries(filters).forEach(async ([key, item]) => {
    const itemEvent = item as EventParam
    const itemPerson = item as PersonParam
    if (itemEvent?.date || itemEvent?.place) {
      // EVENTS
      const eventKey = likeKeys[key]
      if (itemEvent.place?.primaryText) {
        params.append(`${eventKey}Place${itemEvent.placeExact ? 'Exact' : ''}`, itemEvent?.place?.primaryText)
      }
      if (itemEvent.date?.begin) {
        datePromises.push(getDateParam(`${eventKey}DateBegin`, itemEvent.date.begin))
      }
      if (itemEvent.date?.end) {
        datePromises.push(getDateParam(`${eventKey}DateEnd`, itemEvent.date.end))
      }
    } else if (key === 'self') {
      // THE PERSON WHO YOU ARE DOING THE SEARCH FOR
      if (itemPerson?.given?.value) {
        params.append(`given${itemPerson.given.exact ? 'Exact' : ''}`, itemPerson.given.value)
      }
      if (itemPerson?.surname?.value) {
        params.append(`surname${itemPerson.surname.exact ? 'Exact' : ''}`, itemPerson.surname.value)
      }
    } else if (key.startsWith('alternateName')) {
      const suffix = parseInt(key.substring(key.length - 1), 10) + 1
      if (itemPerson?.given?.value) {
        params.append(`given${suffix}${itemPerson.given.exact ? 'Exact' : ''}`, itemPerson.given.value)
      }
      if (itemPerson?.surname?.value) {
        params.append(`surname${suffix}${itemPerson.surname.exact ? 'Exact' : ''}`, itemPerson.surname.value)
      }
    } else if (itemPerson?.given || itemPerson?.surname) {
      // SPOUSE AND PARENTS
      if (itemPerson.given?.value) {
        params.append(`${key}Given${itemPerson.given.exact ? 'Exact' : ''}`, itemPerson.given.value)
      }
      if (itemPerson.surname?.value) {
        params.append(`${key}Surname${itemPerson.surname.exact ? 'Exact' : ''}`, itemPerson.surname.value)
      }
      if (itemPerson.gender) {
        params.append(`${key}Gender`, itemPerson.gender)
      }
    }
  })

  await Promise.all(datePromises).then((results) => {
    results.forEach(([key, value]) => {
      params.append(key, value)
    })
    return params
  })

  return Promise.resolve(params)
}

export async function doSearch(params: SearchParams, startIndex = 0): Promise<AncestorSearch> {
  // const endpointUrl = 'https://httpbin.org/status/500'
  const searchParams = await getSearchParams(params)
  searchParams.append('count', `${PAGE_SIZE}`)
  const endpointUrl = `${treeDataBaseUrl}/published/persons/search?${searchParams.toString()}`

  return axios
    .get(endpointUrl, { headers: { 'x-familysearch-locale': i18n.language } })
    .then((response) => {
      if (window?.location?.hostname === 'localhost') {
        if (!Array.isArray(response.data)) response.data = []
        response.data.forEach((result) => {
          result.person.url = `${window.location.origin}/${getPathFromUrl(result.person.url)}`
        })
      }
      return { records: response.data, recordCount: response.data?.length, startIndex, pageSize: PAGE_SIZE, params }
    })
    .catch((error) => {
      console.error(`Error reading ${endpointUrl}`, error)
      return { error, params, startIndex, pageSize: PAGE_SIZE }
    })
}

type SearchTreeResultsParams = {
  'q.birthLikeDate.from'?: string
  'q.birthLikeDate.to'?: string
  'q.birthLikePlace'?: string
  'q.deathLikeDate.from'?: string
  'q.deathLikeDate.to'?: string
  'q.deathLikePlace'?: string
  'q.givenName'?: string
  'q.surname'?: string
}

export async function getTreeFindUrl(params?: SearchParams): Promise<string> {
  const searchResultsParams: SearchTreeResultsParams = {}
  if (params?.birth?.date?.begin) searchResultsParams['q.birthLikeDate.from'] = params.birth.date.begin
  if (params?.birth?.date?.end) searchResultsParams['q.birthLikeDate.to'] = params.birth.date.end
  if (params?.birth?.place?.primaryText) searchResultsParams['q.birthLikePlace'] = params.birth.place.primaryText

  if (params?.death?.date?.begin) searchResultsParams['q.deathLikeDate.from'] = params.death.date.begin
  if (params?.death?.date?.end) searchResultsParams['q.deathLikeDate.to'] = params.death.date.end
  if (params?.death?.place?.primaryText) searchResultsParams['q.deathLikePlace'] = params.death.place.primaryText

  if (params?.self?.given?.value) searchResultsParams['q.givenName'] = params.self.given.value
  if (params?.self?.surname?.value) searchResultsParams['q.surname'] = params.self.surname.value

  const urlParams = new URLSearchParams(searchResultsParams)

  return `${getExternalBaseUrl()}/search/tree/${urlParams.toString() ? `results?${urlParams.toString()}` : 'name'}`
}
