import React from 'react'
import { css } from '@emotion/core'
import { Link } from '@fs/zion-router'
import { colors } from '@fs/zion-ui'
import type { SurnameIndexNavItem } from '../../types/data-types'
import { getPathFromUrl } from '../../services/url-service'

const linkCss = css`
  margin-right: 16px;

  & a,
  & a:visited {
    color: ${colors.blue50};
  }
`

const indexNavCss = css`
  margin: 16px -24px;
  margin-bottom: 0;
  padding: 0 24px;
  overflow-x: auto;
`

export default function IndexNav({ items }: { items: SurnameIndexNavItem[] | undefined }): JSX.Element {
  return (
    <div css={indexNavCss}>
      {items &&
        items.map((item) => {
          return (
            <span css={linkCss} key={item.bucketName} data-testid={`bucket-${item.bucketName}`}>
              {item.current && <span>{item.bucketName}</span>}
              {!item.current && (
                <Link data-testid="index-nav" to={getPathFromUrl(item.url, true)} linkName="Navigation">
                  {item.bucketName}
                </Link>
              )}
            </span>
          )
        })}
    </div>
  )
}
