import { i18n } from '@fs/zion-locale'
import type { AxiosError } from '@fs/zion-axios'
import type { LocalizedData, TfData, TfEvent, TfPerson } from '@fs/zion-tree-types'
import type { AncestorData, NameMeaning } from '../types/data-types'
import type { Family } from '../types/families'
import type { Person } from '../types/persons'
import convertPerson from './convert-person'
import { convertRelationshipEvent } from './convert-events'

function localizeEvents(events: TfEvent[] | undefined, localizedData: LocalizedData): TfEvent[] | undefined {
  events?.forEach((event) => {
    event.date = event.date && (localizedData[event.date] as string)
    event.place = event.place && (localizedData[event.place] as string)
  })
  return events
}

function convertPeople(s3People: TfPerson[] | undefined, localizedData: LocalizedData): Person[] | undefined {
  return s3People?.map((s3Person) => convertPerson(s3Person, localizedData))
}

function convertNameMeanings(s3MeaningsArray): NameMeaning[] | undefined {
  let convertedMeaningsArray: NameMeaning[] = []
  convertedMeaningsArray = s3MeaningsArray?.map((m) => getNameMeaningObject(m))

  return convertedMeaningsArray
}

function getNameMeaningObject(meaning): NameMeaning {
  const name = meaning.name
  const meanings = meaning.meanings
  const type = meaning.type
  const variants = meaning.variants
  const attribution = meaning.attribution

  return { name, meanings, type, variants, attribution }
}

function extractChildren(parent1, parent2, children, localizedDataByLang): Person[] {
  return children?.reduce((acc, s3Child) => {
    const { otherParentId } = s3Child
    if (otherParentId === parent1.id || otherParentId === parent2.id) {
      acc.push(convertPerson(s3Child, localizedDataByLang))
    }
    return acc
  }, [])
}

function extractFamilies(person, events, spouses, children, localizedDataByLang): Family[] {
  const families: Family[] = []
  const spouseEvents = events.filter(({ type }) => type === 'Spousal')
  spouses?.forEach((spouse) => {
    const isFemaleWithHusband = person?.gender === 'FEMALE' && spouse?.gender === 'MALE'
    const isUnknownSpouse = spouse.id === 'UNKNOWN'
    const spousalEvent = spouseEvents.find(({ id }) => spouse.id === id)
    const parent1 = isFemaleWithHusband ? spouse : person
    const parent2 = isFemaleWithHusband ? person : spouse
    const family: Family = {
      parent1,
      parent2: isUnknownSpouse ? undefined : parent2, // We do not want to display an unknown spouse in the UI
      children: extractChildren(person, spouse, children, localizedDataByLang),
    }
    if (spousalEvent) {
      family.event = convertRelationshipEvent(spousalEvent)
    }
    families.push(family)
  })

  return families
}

export default function convert(s3Data: TfData): AncestorData {
  if (s3Data.removed) throw createError(410)
  if (s3Data.forwarded) throw createError(301, s3Data.forwarded)
  const { person: s3Person, localizedData } = s3Data
  const {
    memories: memoriesCount,
    events,
    sourceList: sources,
    sources: sourcesCount,
    spouse: s3Spouses,
    popularStory,
    memoryArtifacts,
    children: s3Children,
    sibling: s3Siblings,
    parent: s3Parents,
  } = s3Person

  const localizedDataByLang = localizedData[i18n.language] || {}

  const person = convertPerson(s3Person, localizedDataByLang)
  const spouses = convertPeople(s3Spouses, localizedDataByLang)
  const parents = convertPeople(s3Parents, localizedDataByLang)
  const siblings = convertPeople(s3Siblings, localizedDataByLang)

  return {
    person,
    memories: {
      memoriesCount,
      memoryArtifacts,
    },
    sources: {
      sourcesCount,
      sources,
    },
    events: localizeEvents(events, localizedDataByLang),
    families: {
      spouses: extractFamilies(person, events, spouses, s3Children, localizedDataByLang),
      // We only receive the preferred parents and their children (siblings) at the moment
      parents: parents
        ? [
            {
              parent1: parents?.[0],
              parent2: parents?.[1],
              children: siblings,
            },
          ]
        : [],
    },
    nameMeanings: convertNameMeanings(localizedDataByLang.nameMeanings),
    lifeSummary: localizedDataByLang.lifeSummary as string,
    historicEvents: localizedDataByLang.historicEvents,
    popularStory,
    userCertifiedLifeSummary: s3Person.userCertifiedLifeSummary,
  }
}

export function createError(status: number, location?: string): AxiosError {
  const error = new Error() as Error & {
    response?: { status: number; headers: { location?: string } }
    isAxiosError?: true
  }
  error.isAxiosError = true
  error.response = {
    status,
    headers: { location },
  }
  return error as AxiosError // It is mostly AxiosError
}
