import React from 'react'
import { useTranslation } from 'react-i18next'
import { i18n } from '@fs/zion-locale'
import { Link } from '@fs/zion-router'
import churchLogos from './ChurchLogos'

export default function ChurchLogo({ restricted }: { restricted?: boolean }): JSX.Element {
  const { t } = useTranslation()
  const churchLogo = churchLogos[i18n.language] ? churchLogos[i18n.language] : churchLogos.en
  const logoElement = <img src={churchLogo} css={{ width: '135px' }} alt={t('ancestors.service-by-church.text')} />
  return (
    <div>
      {restricted ? (
        <>{logoElement}</>
      ) : (
        <Link
          data-testid="come-unto-christ-link"
          to="https://www.comeuntochrist.org"
          linkName="Footer: Church Logo"
          title={t('ancestors.service-by-church.text')}
          external
        >
          {logoElement}
        </Link>
      )}
    </div>
  )
}
