import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { MenuMore } from '@fs/zion-icon'
import { useLocation, Switch, Route } from '@fs/zion-router'
import { useUser } from '@fs/zion-user'
import { Paragraph, Button, IconButton, colors, MenuOverlay, ListItem, useContainerWidth, Row } from '@fs/zion-ui'
import { HelpOverlayButton } from '@fs/zion-help-overlay'
import { boolAttr } from '@fs/zion-frontend-friends'
import FSLogo from './FSLogo'
import { getExternalBaseUrl } from '../../services/url-service'
import { useDataContext } from '../../services/data-service'
import AncestorPageHeader from '../AncestorPage/AncestorPageHeader'
import { ANCESTOR_PAGE_PATHS } from '../../App'

const shadowCss = css`
  position: relative;
  z-index: 2;
  &[sticky] {
    z-index: 2;
    position: sticky;
    top: -60px;
  }
`

const headerCss = css`
  align-items: center;
  background: ${colors.gray00};
  display: flex;
  height: 60px;
  justify-content: space-between;
  padding: 0 24px;

  &[is-mobile] {
    padding: 0 12px;
  }
`

const nameCss = css`
  p {
    padding: 0;
  }
`

export default function AncestorsHeader(): JSX.Element {
  const {
    store: { CF = false },
  } = useDataContext()
  const [t] = useTranslation()
  const user = useUser()
  const pageLocation = useLocation()
  const signedIn = user.signedIn
  const identityUrl = window.location.href.includes('localhost') ? window.location.origin : getExternalBaseUrl()
  // We use useLocation to keep our url state current. window.location.href doesn't stay current.
  const returnUrl = encodeURIComponent(window.location.origin + pageLocation.pathname)

  const atWidth = useContainerWidth()
  const isMobile = atWidth({ default: true, md: false })
  const headerButtons = atWidth({
    default: (
      <MenuOverlay
        menuButton={
          <IconButton data-testid="more-button" color="gray" Icon={MenuMore} title={t('ancestors.open-menu.title')} />
        }
      >
        <ListItem
          primaryText={t('common-ui:sign-in.action')}
          external
          data-testid="go-to-log-in"
          to={`${identityUrl}/auth/familysearch/login?returnUrl=${returnUrl}`}
          linkName="Log In"
        />
        <ListItem
          primaryText={t('ancestors.create-account.action')}
          external
          data-testid="go-to-create-account"
          to={`${identityUrl}/register/?returnUrl=${returnUrl}`}
          linkName="Create Account"
        />
      </MenuOverlay>
    ),
    md: (
      <Row gutters="xs">
        <Button
          data-testid="go-to-log-in"
          emphasis="low"
          external
          to={`${identityUrl}/auth/familysearch/login?returnUrl=${returnUrl}`}
          linkName="Log In"
        >
          {t('common-ui:sign-in.action')}
        </Button>
        <Button
          data-testid="go-to-create-account"
          emphasis="medium"
          external
          to={`${identityUrl}/register/?returnUrl=${returnUrl}`}
          linkName="Create Account"
        >
          {t('ancestors.create-account.action')}
        </Button>
      </Row>
    ),
  })

  return (
    <div css={shadowCss}>
      <header css={headerCss} is-mobile={boolAttr(isMobile)}>
        <FSLogo restricted={CF} />
        {!CF && (
          <Row gutters="xs">
            <HelpOverlayButton data-testid="help-overlay-button" />
            {!signedIn && !user.userLoading ? (
              headerButtons
            ) : (
              <>
                {!user.userLoading && signedIn && (
                  <Row gutters="xs" alignY="middle">
                    {!isMobile && (
                      <div css={nameCss}>
                        <Paragraph size="sm">{user?.displayName}</Paragraph>
                      </div>
                    )}
                    <Button
                      data-testid="go-to-sign-out"
                      emphasis="medium"
                      external
                      to={`${identityUrl}/auth/logout/?returnUrl=${returnUrl}`}
                      linkName="Log out"
                    >
                      {t('ancestors.sign-out.action')}
                    </Button>
                  </Row>
                )}
              </>
            )}
          </Row>
        )}
      </header>
      <div>
        <Switch>
          <Route exact path={ANCESTOR_PAGE_PATHS}>
            <AncestorPageHeader />
          </Route>
        </Switch>
      </div>
    </div>
  )
}
