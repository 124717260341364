import React from 'react'
import { useUser } from '@fs/zion-user'
import WelcomeBanner from './WelcomeBanner'

export default function AncestorsPageHeader(): JSX.Element {
  const { signedIn } = useUser()
  const showWelcomeBanner = signedIn

  return <div>{showWelcomeBanner && <WelcomeBanner />}</div>
}
