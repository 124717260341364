import type { Gender } from '../types/persons'
import useData from './data-service'

export default function useLifeOf(): { name: string; text: string | undefined; sex: Gender } | undefined {
  const data = useData()
  const person = data?.person

  if (!person || !data) return undefined
  return { name: person.name, text: data.userCertifiedLifeSummary || data.lifeSummary, sex: person.gender }
}
