import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from '@fs/zion-router'
import { List, ListItem, PersonBlock } from '@fs/zion-ui'
import { trackEvent } from '@fs/zion-analytics'
import type { SurnameIndexPersonItem } from '../../types/data-types'
import { getName } from '../../services/surname-index-service'

const listItemCss = css`
  padding-top: 4px;
`

function getSex(url: string | undefined): 'male' | 'female' | 'unknown' {
  return (
    (url?.substring(url.lastIndexOf('/') + 1).toLowerCase() as 'male' | 'female' | 'unknown' | undefined) || 'unknown'
  )
}

export default function PersonList({ items }: { items: SurnameIndexPersonItem[] | undefined }): JSX.Element {
  const [t] = useTranslation()
  const history = useHistory()

  return (
    <List>
      {items &&
        items.map((item) => {
          return (
            <ListItem
              data-testid="person-list-item"
              primaryText={
                <div css={listItemCss}>
                  <PersonBlock
                    size="sm"
                    avatarProps={{
                      sex: getSex(item.gender),
                    }}
                    name={getName(t, item.givenNames, item.surname)}
                    details={`${item.lifespan}`}
                  />
                </div>
              }
              onClick={() => {
                if (item?.url) {
                  window?.scrollTo && window?.scrollTo(0, 0)
                  history.push(`/${item.personId}/${item.url.substring(item.url.lastIndexOf('/') + 1)}`)
                }
                trackEvent({ link_name: 'Person' })
              }}
              key={item.personId}
              dense
            />
          )
        })}
    </List>
  )
}
