import React, { useEffect, useRef } from 'react'
import { useZionFormContext, FormPlaceSuggest } from '@fs/zion-form'
import { useContainerWidth } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

const placeFieldCss = css`
  & .autosuggest-wrapper {
    max-height: 72px;
  }
`

const keyMap = {
  birth: 'ancestors.birth.place.label',
  death: 'ancestors.death.place.label',
}

export default function PlaceField({
  type,
  orientation,
}: {
  type: 'birth' | 'death'
  orientation?: string
}): JSX.Element {
  const atWidth = useContainerWidth()
  const [t] = useTranslation()
  const { setValue, getValues } = useZionFormContext()

  const isMobile = atWidth({ default: true, md: false })
  const fullWidth = isMobile || orientation === 'vertical'
  const placeValues = getValues(type)?.place
  const placeLabel = fullWidth ? t('ancestors.place.condensed-label') : t(keyMap[type])
  const initialSelectedItem = placeValues?.primaryText
    ? { isStandard: getValues(type)?.place?.isStandard, primaryText: placeValues?.primaryText }
    : undefined
  const initialRenderRef = useRef(true)

  useEffect(() => {
    let cancelled = false
    if (!cancelled && initialRenderRef.current) {
      setValue(type, getValues(type) ?? { place: { primaryText: '' } })
      initialRenderRef.current = false
    }

    return () => {
      cancelled = true
    }
  }, [type, setValue, getValues])

  return (
    <div css={placeFieldCss}>
      <FormPlaceSuggest
        label={placeLabel}
        name={`${type}_place`}
        data-testid={`${type}-place`}
        initialSelectedItem={initialSelectedItem}
      />
    </div>
  )
}
