import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from '@fs/zion-router'
import { i18n } from '@fs/zion-locale'
import logo from './familysearch-tree.svg'
import { getExternalBaseUrl } from '../../services/url-service'
import useDefaultScripts from '../../services/default-scripts-service'

export default function FSLogo({ restricted }: { restricted: boolean }): JSX.Element {
  const { t } = useTranslation()
  const alt = t('ancestors.family-search.alt-text')
  const scripts = useDefaultScripts() || {}

  const linkBaseUrl = restricted ? window?.location?.origin : getExternalBaseUrl()
  const linkPath = `${i18n.language}${restricted ? `/surnames/${scripts[i18n.language] || 'latn'}` : ''}`

  return (
    <Link data-testid="fs-logo-link" to={`${linkBaseUrl}/${linkPath}/`} linkName="Header: FamilySearch Logo" external>
      <img src={logo} width="132" height="33.8" alt={alt} aria-label={alt} />
    </Link>
  )
}
