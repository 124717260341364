import React from 'react'
import Head, { Title } from '@fs/zion-head'
import { i18n } from '@fs/zion-locale'
import { getSchemaOrgPage, getSchemaOrgPerson } from '../services/web-page-schema'
import type { Person } from '../types/persons'
import type { Families } from '../types/data-types'

function sanitizeJSON(unsanitized: string | undefined): string | undefined {
  return unsanitized?.replace(/</g, '&lt;').replace(/>/g, '&gt;')
}

export default function PageHead({
  title,
  description,
  socialShareTitle,
  socialShareDescription,
  url,
  image,
  person,
  families,
}: {
  title: string | undefined
  description?: string | undefined
  socialShareTitle?: string | undefined
  socialShareDescription?: string | undefined
  url?: string | undefined
  image?: Record<string, unknown> | undefined
  person?: Person | undefined
  families?: Families | undefined
}): JSX.Element {
  const pageUrl = url || window?.location?.href
  const ogDescription = socialShareDescription || description
  const ogTitle = `${socialShareTitle || title} • FamilySearch`
  const jsonld = person && families ? getSchemaOrgPerson(person, families) : undefined
  const webPageJsonld = getSchemaOrgPage(i18n.language, ogTitle, ogDescription, person)
  return (
    <>
      <Title>{title}</Title>
      <Head>
        <meta name="description" content={description} />
        <meta property="fb:admins" content="1047550305" />
        <meta property="fb:app_id" content="1261365907621824" />
        <meta property="og:locale" content={i18n.language} />
        <meta property="og:site_name" content="FamilySearch" />
        <meta property="og:type" content="profile" />
        {ogTitle !== undefined && <meta property="og:title" content={ogTitle} />}
        {ogDescription !== undefined && <meta property="og:description" content={ogDescription} />}
        {image?.url !== undefined && <meta property="og:image" content={`${image.url}`} />}
        {image?.url !== undefined && <meta property="og:image:secure_url" content={`${image.url}`} />}
        {image?.alt !== undefined && <meta property="og:image:alt" content={`${image.alt}`} />}
        {image?.width !== undefined && <meta property="og:image:width" content={`${image.width}`} />}
        {image?.height !== undefined && <meta property="og:image:height" content={`${image.height}`} />}
        <meta property="og:url" content={pageUrl} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@familysearch" />
        <meta name="twitter:creator" content="@familysearch" />
        <link rel="canonical" href={pageUrl} />
        {jsonld !== undefined && <script type="application/ld+json">{sanitizeJSON(JSON.stringify(jsonld))}</script>}
        <script type="application/ld+json">{sanitizeJSON(JSON.stringify(webPageJsonld))}</script>
      </Head>
    </>
  )
}
