import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { Paragraph, H2 } from '@fs/zion-ui'
import NoResults from './NoResults.svg'

const contentCss = css`
  margin: 50px auto;
  text-align: center;

  & img {
    margin-bottom: 20px;
  }
`
const subtextCss = css`
  margin: 0 auto;
  max-width: 500px;
  text-align: center;
`

export default function SearchError(): JSX.Element {
  const [t] = useTranslation()

  return (
    <div css={contentCss}>
      <img src={NoResults} alt={t('ancestors.server-error.header')} />
      <div data-testid="search-error">
        <H2 size="H3" centered>
          {t('ancestors.server-error.header')}
        </H2>
        <div css={subtextCss}>
          <Paragraph>{t('ancestors.server-error.text')}</Paragraph>
        </div>
      </div>
    </div>
  )
}
