import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Divider, InlineButton, Paragraph, Card, Image, Bleed, colors, Separator } from '@fs/zion-ui'
import { css } from '@emotion/core'
import AdvancedSearchCardImg from './AdvancedSearchCard.svg'

const cardWrapperCss = css`
  height: 100%;
  & > * {
    height: 100%;
    & > * {
      height: 100%;
    }
  }
  width: 300px;
`

type Props = {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div'
  treeFindUrl: string
}

export default function AdvancedSearchCard({ treeFindUrl, as: Tag = 'div' }: Props): JSX.Element {
  const [t] = useTranslation()
  const headerText = t('ancestors.advanced-search-card.header')
  const subHeaderText = t('ancestors.advanced-search-card.subheader')
  const livingText = t('ancestors.advanced-search-card.living.text')

  return (
    <div css={cardWrapperCss}>
      <Tag data-testid="advanced-search-card-heading">
        <Card backgroundColor={colors.gray02}>
          <Bleed all>
            <Image alt="" src={AdvancedSearchCardImg} />
          </Bleed>

          <Separator size="nano" />

          <Paragraph>{headerText}</Paragraph>
          <Paragraph secondary size="sm">
            {subHeaderText}
          </Paragraph>

          <Button data-testid="go-to-tree-find" emphasis="low" to={treeFindUrl} linkName="Family Tree Search">
            {t('ancestors.family-tree-search.action')}
          </Button>

          <Divider marginY="xxs" />

          <Paragraph secondary size="sm">
            {livingText}{' '}
            <InlineButton
              data-testid="go-help-private-spaces"
              external
              to="https://www.familysearch.org/help/helpcenter/article/what-is-a-private-space-in-family-tree"
              linkName="Learn More"
            >
              {t('common-ui:learn-more.action')}
            </InlineButton>
          </Paragraph>
        </Card>
      </Tag>
    </div>
  )
}
