import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { Paragraph, Button, Separator, H2 } from '@fs/zion-ui'
import { Title } from '@fs/zion-head'
import PersonNotFound from './PersonNotFound.svg'
import PersonBanner from '../person-banner/PersonBanner'

const imageCss = css`
  background-image: url(${PersonNotFound});
  background-color: #c4c4c4;
  background-position: center bottom;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100vh;
`

const contentCss = css`
  margin-top: 50px;
  text-align: center;

  & img {
    max-width: 390px;
  }
`

export default function ServerError(): JSX.Element {
  const [t] = useTranslation()

  return (
    <div css={imageCss}>
      <Title>{`${t('ancestors.error-page.meta-data.title')} | ${t('ancestors.server-error.header')}`}</Title>
      <PersonBanner />
      <div data-testid="server-error" css={contentCss}>
        <H2 centered size="H3">
          {t('ancestors.server-error.header')}
        </H2>
        <Paragraph centered>{t('ancestors.server-error.text')}</Paragraph>

        <Separator size="md" />

        <Button
          data-testid="server-error-button"
          emphasis="medium"
          onClick={() => {
            window?.location.reload()
          }}
        >
          {t('ancestors.reload-page.action')}
        </Button>
      </div>
    </div>
  )
}
