import axios from '@fs/zion-axios'
import convertFromS3 from './convert-from-s3'
import type { AncestorData } from '../types/data-types'
import { treeDataBaseUrl } from './url-service'
import type { CustomWindow } from '../types/window'

export default async function readData(pid: string): Promise<AncestorData> {
  const endpointUrl = `${treeDataBaseUrl}/published/persons/${pid}`

  const win = window as CustomWindow
  const CF = win?.SERVER_DATA?.ANCESTORS?.CF || false

  try {
    const response = await axios.get(endpointUrl, {
      headers: {
        'x-cf': CF ? 'on' : 'off',
      },
    })

    return convertFromS3(response.data)
  } catch (error) {
    console.error(`Error reading ${treeDataBaseUrl}/${pid}`, error)
    throw error
  }
}
