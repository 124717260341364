import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from '@fs/zion-router'
import type { TFunction } from 'i18next'
import axios from '@fs/zion-axios'
import readData from './surname-index-reader'
import type { SurnameIndexData } from '../types/data-types'

type Store = {
  data?: SurnameIndexData
  error?: Error
  url?: string
  loading: boolean
  notFound: boolean
  serverError: boolean
}

const PrivateContext = React.createContext<Store>(initStore())

export default function useSurnameIndex(): SurnameIndexData | undefined {
  const store = useContext(PrivateContext)
  if (!store) throw new Error('useSurnameIndex must be used within a SurnameIndexContext')
  return store.data
}

export function useSurnameIndexStatus(): Store {
  return useContext(PrivateContext)
}

function initStore(url?: string, data?: SurnameIndexData, error?: unknown): Store {
  const loading = !data && !error
  const axiosError = axios.isAxiosError(error) && error
  const notFound = axiosError && error?.response?.status === 404
  const serverError = axiosError && error?.response?.status === 500
  return { url, data, error: axiosError || undefined, loading, notFound, serverError }
}

export function getName(translate: TFunction, firstName: string | undefined, lastName: string | undefined): string {
  if (firstName && lastName) {
    return translate('ancestors.surname-index.range.start', { givenName: firstName, surname: lastName })
  }
  if (!firstName && lastName) return lastName
  if (!lastName && firstName) return firstName
  return ''
}

export type DataProps = {
  data?: SurnameIndexData
  children?: React.ReactElement
}
export function SurnameIndexContext({ data, children }: DataProps): JSX.Element {
  const location = useLocation()
  const [store, setStore] = useState(initStore('', data))

  useEffect(() => {
    let cancelled = false
    let path = location.pathname
    const index = location.pathname.indexOf('/cb')
    if (index !== -1) {
      path = location.pathname.substring(0, index)
    }
    async function read(url: string): Promise<void> {
      let d: SurnameIndexData | undefined
      let e: unknown | undefined

      try {
        d = await readData(url)
      } catch (error) {
        e = error
      }
      if (!cancelled) {
        if (d?.error) {
          e = d.error
        }
        setStore(initStore(url, d, e))
      }
    }
    if (!cancelled) {
      if (location.pathname !== store.url) {
        read(location.pathname)
      }
      if (path !== store.url) {
        read(path)
      }
    }

    return () => {
      cancelled = true
    }
  }, [location.pathname, store])

  return <PrivateContext.Provider value={store}>{children}</PrivateContext.Provider>
}
