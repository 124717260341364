import axios from '@fs/zion-axios'
import type { DefaultScripts } from '../types/data-types'
import { treeDataBaseUrl } from './url-service'

export default async function readDefaultScripts(): Promise<DefaultScripts> {
  const endpointUrl = `${treeDataBaseUrl}/published/surnames/scripts`
  try {
    const response = await axios.get(endpointUrl)
    const data: DefaultScripts = response.data

    return data
  } catch (error) {
    console.error(`Error reading ${endpointUrl}`, error)
    throw error
  }
}
