import React from 'react'
import { css } from '@emotion/core'
import { Paragraph, Button, Divider, Separator, useContainerWidth, LayoutBand, Row, H2 } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { i18n } from '@fs/zion-locale'
import { useLocation } from '@fs/zion-router'
import portraitImage from './assets/landing-portrait.jpg'
import recordImage from './assets/landing-record.png'
import { getExternalBaseUrl } from '../../services/url-service'
import useDefaultScripts from '../../services/default-scripts-service'

const homePageBodyCss = css`
  padding-top: 32px;
  padding-bottom: 32px;
`

const surnameContainerCss = css`
  text-align: center;
  width: 100%;
`

const imageCss = css`
  height: 256px;
  width: 256px;
`

export default function HomePageBody(): JSX.Element {
  const atWidth = useContainerWidth()
  const isMobile = atWidth({ default: true, lg: false })
  const [t] = useTranslation()
  const location = useLocation()
  const scripts = useDefaultScripts() || {}
  const doExternalLink = location.pathname.endsWith(`/${i18n.language}`) || window?.location?.hostname === 'localhost'

  return (
    <LayoutBand maxWidth="sm" css={homePageBodyCss}>
      <div css={surnameContainerCss}>
        <H2 size="H4">{t('ancestors.surname-directory.header')}</H2>
        <Paragraph centered>{t('ancestors.surname-directory.description')}</Paragraph>

        <Separator size="nano" />

        <Button
          data-testid="browse-surnames"
          to={`${doExternalLink ? `/${i18n.language}` : ''}/surnames/${scripts[i18n.language] || 'latn'}`}
          linkName="Browse Surname Directory"
          external={doExternalLink}
          emphasis="high"
        >
          {t('ancestors.surname-directory.action')}
        </Button>
      </div>

      <Divider marginY="md" />

      <Row wrap={isMobile} gutters="md" alignY="middle" alignX="center">
        <img noShrinkX src={portraitImage} css={imageCss} alt={t('ancestors.portrait.alt')} />

        <div>
          <H2 size="H4">{t('ancestors.memories-section.header')}</H2>
          <Paragraph>{t('ancestors.memories-section.description')}</Paragraph>

          <Separator size="nano" />

          <Button to={`${getExternalBaseUrl()}/memories`} linkName="Get Started" data-testid="get-started">
            {t('ancestors.portrait.link')}
          </Button>
        </div>
      </Row>

      <Separator size="md" />

      <Row wrap={isMobile} gutters="md" alignY="middle" alignX="center">
        {isMobile && <img noShrinkX src={recordImage} css={imageCss} alt={t('ancestors.record.alt')} />}

        <div>
          <H2 size="H4">{t('ancestors.historical-records.header')}</H2>
          <Paragraph>{t('ancestors.historical-records.description')}</Paragraph>

          <Separator size="nano" />

          <Button to={`${getExternalBaseUrl()}/search`} linkName="View Records Now" data-testid="view-records-now">
            {t('ancestors.historical-records.action')}
          </Button>
        </div>
        {!isMobile && <img noShrinkX src={recordImage} css={imageCss} alt={t('ancestors.record.alt')} />}
      </Row>
    </LayoutBand>
  )
}
