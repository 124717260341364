import React, { useCallback } from 'react'
import { useContainerWidth, Separator, WhiteTheme, LayoutBand, H1, Paragraph, H2 } from '@fs/zion-ui'
import { i18n } from '@fs/zion-locale'
import { useHistory, useLocation } from '@fs/zion-router'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import i18next from 'i18next'
import image from './assets/landing-family.jpg'
import Search from '../search/Search'
import { getCachedSearchParams, setCachedSearchParams } from '../../services/search-service'
import type { SearchParams } from '../../types/data-types'

const mainContainerCss = css`
  padding-top: 24px;
  padding-bottom: 24px;
  background-image: linear-gradient(90deg, #a0024cbb, #279aadbb), url('${image}');
  background-size: cover;
  background-position: center 10%;
`

export default function HomePageHeader(): JSX.Element {
  const [t] = useTranslation()
  const location = useLocation()
  const atWidth = useContainerWidth()
  const isMobile = atWidth({ md: false, default: true })
  const history = useHistory()
  const initialParams = getCachedSearchParams()
  const doExternalLink =
    location.pathname.endsWith(`/${i18n.language}`) ||
    location.pathname.endsWith(`/${i18n.language}/`) ||
    window?.location?.hostname === 'localhost'

  const onSearchSubmit = useCallback(
    (params: SearchParams): void => {
      setCachedSearchParams(params)
      if (window?.location?.href) {
        history.push(`/${doExternalLink ? i18next.language : 'en'}/search`)
      }
    },
    [doExternalLink, history]
  )

  return (
    <WhiteTheme>
      <LayoutBand css={mainContainerCss} maxWidth="xs">
        <H1 centered size="H3">
          {t('ancestors.page.header')}
        </H1>
        <Separator size="nano" />
        <Paragraph centered>{t('ancestors.page.subheader')}</Paragraph>

        <Separator size={isMobile ? 'sm' : 'xl'} />

        <Search
          HeadingComponent={H2}
          params={initialParams}
          header={t('ancestors.search-for-ancestor.header')}
          onSubmit={onSearchSubmit}
          data-testid="home-page-header-search-form"
        />
      </LayoutBand>
    </WhiteTheme>
  )
}
