import React from 'react'
import { css } from '@emotion/core'
import { Paragraph, colors, Button, LayoutBand, H2, Separator } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { getExternalBaseUrl } from '../../services/url-service'

const homePageFooterCss = css`
  background: ${colors.gray03};
  padding-top: 32px;
  padding-bottom: 32px;
`

export default function HomePageFooter(): JSX.Element {
  const [t] = useTranslation()

  return (
    <LayoutBand maxWidth="sm" css={homePageFooterCss}>
      <H2 size="H4">{t('ancestors.about-family-search.header')}</H2>
      <Paragraph>{t('ancestors.about-family-search.description')}</Paragraph>

      <Separator size="nano" />

      <Button
        to={`${getExternalBaseUrl()}/register`}
        external
        linkName="Footer: Free Account"
        data-testid="home-footer-free-account"
        emphasis="high"
      >
        {t('ancestors.free-acount.link')}
      </Button>
    </LayoutBand>
  )
}
