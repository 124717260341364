import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { H2, Expander, ExpanderContent, Button, useContainerWidth, Separator, Grid, Row } from '@fs/zion-ui'
import ZionForm, { useZionForm, FormTextField, useZionFormContext } from '@fs/zion-form'
import { ArrowCaret } from '@fs/zion-icon'
import { i18n } from '@fs/zion-locale'
import { trackEvent } from '@fs/zion-analytics'
import type { SearchParams } from '../../types/data-types'
import EventFields from './EventFields'

const nameFieldSwapLocalesRegEx = /^ja|^km|^ko|^mn|^ro|^to|^vi|^zh/

type SearchButtonProps = {
  'data-testid': string
}

function SearchButton({ 'data-testid': dataTestId }: SearchButtonProps): JSX.Element {
  const [t] = useTranslation()
  const atWidth = useContainerWidth()
  const isMobile = atWidth({ md: false, default: true })
  const { formState } = useZionFormContext()
  const { isValid, isDirty } = formState
  return (
    <Button type="submit" emphasis="high" fullWidth={isMobile} disabled={!isValid || !isDirty} data-testid={dataTestId}>
      {t('common-ui:search.action')}
    </Button>
  )
}

export default function Search({
  header,
  params,
  onSubmit,
  showMore,
  onSearchPage = false,
  'data-testid': dataTestId,
  HeadingComponent = H2,
}: {
  header?: string | undefined
  params?: SearchParams
  onSubmit?: (values: SearchParams) => void
  showMore?: boolean
  onSearchPage?: boolean
  'data-testid'?: string
  HeadingComponent?: typeof H2
}): JSX.Element {
  const [t] = useTranslation()
  const atWidth = useContainerWidth()
  const isMobile = atWidth({ md: false, default: true })
  const methods = useZionForm({
    mode: 'all',
    resolver: validate,
    defaultValues: params,
  })
  const swapNames = nameFieldSwapLocalesRegEx.test(i18n.language)
  const [open, setOpen] = useState(false)

  function validate(values: SearchParams): { values: unknown; errors: unknown } {
    const error = !values.self?.surname?.value
    return {
      values: error ? {} : values,
      errors: error ? { self: { surname: { value: { message: t('ancestors.fields.required') } } } } : {},
    }
  }

  const handleSubmit = useCallback(
    (values: SearchParams): void => {
      if (onSubmit) {
        onSubmit(values)
      }
      trackEvent({ link_name: 'Search' })
    },
    [onSubmit]
  )

  return (
    <ZionForm methods={methods} onSubmit={handleSubmit} data-testid={dataTestId}>
      <Separator size="xxs" />
      {header && (
        <HeadingComponent centered size="H4">
          {header}
        </HeadingComponent>
      )}

      <NameFields swapNames={swapNames} onSearchPage={onSearchPage} />

      {showMore && (
        <>
          <Expander open={open} setOpen={setOpen}>
            <ExpanderContent>
              <EventFields type="birth" />
              <EventFields type="death" />
            </ExpanderContent>
          </Expander>

          <Separator size="xxs" />

          <Grid>
            <Button
              fullWidth={isMobile}
              data-testid="show-more-less-button"
              linkName={open ? 'Less Search Options' : 'More Search Options'}
              onClick={(event) => {
                setOpen(!open)
                event.target.blur()
              }}
              Icon={ArrowCaret}
              iconDirection={open ? 'up' : 'down'}
            >
              {open ? t('ancestors.less-search-options.action') : t('ancestors.more-search-options.action')}
            </Button>
            {isMobile && <SearchButton data-testid="search-button" />}
          </Grid>
        </>
      )}
    </ZionForm>
  )
}

type NameFieldsProps = {
  swapNames?: boolean
  onSearchPage?: boolean
}

function NameFields({ swapNames, onSearchPage }: NameFieldsProps): JSX.Element {
  const [t] = useTranslation()
  const atWidth = useContainerWidth()
  const isMobile = atWidth({ md: false, default: true })
  const Wrapper = isMobile ? Grid : Row

  const growXProp = isMobile ? {} : { growX: true }

  return (
    <Wrapper guttersY="none">
      {swapNames ? (
        <FormTextField
          {...growXProp}
          data-testid="search-surname"
          label={t('ancestors.surname.label')}
          name="self.surname.value"
        />
      ) : (
        <FormTextField
          {...growXProp}
          data-testid="search-given-name"
          label={t('ancestors.given-name.label')}
          name="self.given.value"
        />
      )}
      {swapNames ? (
        <FormTextField
          {...growXProp}
          data-testid="search-given-name"
          label={t('ancestors.given-name.label')}
          name="self.given.value"
        />
      ) : (
        <FormTextField
          {...growXProp}
          data-testid="search-surname"
          label={t('ancestors.surname.label')}
          name="self.surname.value"
        />
      )}

      {!(isMobile && onSearchPage) && (
        <Row alignX="center">
          <SearchButton noShrinkX growX alignY={isMobile ? undefined : 'nextToInput'} data-testid="search-button" />
        </Row>
      )}
    </Wrapper>
  )
}
