import React from 'react'
import { boolAttr } from '@fs/zion-frontend-friends'
import { Link, useParams } from '@fs/zion-router'
import { Button, useContainerWidth } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { getExternalBaseUrl } from '../../services/url-service'

const bannerCss = css`
  align-items: center;
  background: #ffffff;
  border-top: 1px solid #d0d3d3;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  justify-content: space-between;
  padding: 8px 16px;

  &[is-mobile] {
    flex-direction: column;
  }

  a:visited {
    color: #0181a1;
  }
`

export default function WelcomeBanner(): JSX.Element {
  const [t] = useTranslation()
  const atWidth = useContainerWidth()
  const { pid } = useParams()

  return (
    <div css={bannerCss} is-mobile={boolAttr(atWidth({ lg: false, default: true }))} data-testid="welcome-banner">
      <div>
        <span>{t('ancestors.welcome-to-discovery-page')}</span>{' '}
        <Link
          to="https://www.familysearch.org/blog/en/ancestor-discovery-page/"
          linkName="Learn about what’s different"
          target="_blank"
          external
          data-testid="learn-about-whats-different"
        >
          {t('ancestors.learn-about-whats-different.action')}
        </Link>
      </div>
      <Button
        data-testid="go-to-detail-person-page"
        linkName="Go to Detail Person Page"
        external
        to={`${getExternalBaseUrl()}/tree/person/details/${pid}`}
      >
        {t('ancestors.go-to-detail-person-page.action')}
      </Button>
    </div>
  )
}
