import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { Link } from '@fs/zion-router'
import { Paragraph, H1 } from '@fs/zion-ui'
import { usePageView } from '@fs/zion-analytics'
import SavedHTMLImage from './SavedHtmlImage'

const containerCss = css`
  background-color: #c4c4c4;
  margin-top: -50px;
  padding-top: 50px;
`
const errorCss = css`
  text-align: center;
  margin-top: 50px;
`

export default function SavedHtmlError(): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- window will have ANCESTORS_URL
  const ancestorsUrl = (window as any).ANCESTORS_URL
  const { t } = useTranslation()

  usePageView({ page_detail: 'Saved Person HTML Error' })

  return (
    <div css={containerCss}>
      <div data-testid="not-found-error" css={errorCss}>
        <H1 size="H3" centered>
          {t('ancestors.not-found-error.header')}
        </H1>
        <Paragraph centered>{t('ancestors.print-html.error')}</Paragraph>
        <Link
          data-testid="fs-link"
          to={ancestorsUrl}
          title={t('ancestors.print-html.action')}
          linkName="Footer: Church Logo"
          external
        >
          {t('ancestors.print-html.action')}
        </Link>
      </div>
      <SavedHTMLImage />
    </div>
  )
}
