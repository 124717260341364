import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { colors, Separator } from '@fs/zion-ui'
import { Link } from '@fs/zion-router'
import { useDataContext } from '../../services/data-service'

const footerCss = css`
  & a,
  & a:visited {
    color: ${colors.blue50};
  }
`

export default function IndexPage(): JSX.Element | null {
  const {
    store: { CF },
  } = useDataContext()
  const [t] = useTranslation()
  return CF ? null : (
    <div css={footerCss}>
      <Separator />
      <Link to="/surnames">{t('ancestors.select-script.action')}</Link>
      <Separator />
    </div>
  )
}
