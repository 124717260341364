import type { TfNameForm } from '@fs/zion-tree-types'
import type { NameForm } from '../types/name-forms'

export default function convertNameForm({ lang, fullText, order, parts }: TfNameForm): NameForm {
  const prefixPart = parts?.find(({ type }) => type.includes('Prefix'))
  const givenPart = parts?.find(({ type }) => type.includes('Given'))
  const familyPart = parts?.find(({ type }) => type.includes('Surname'))
  const suffixPart = parts?.find(({ type }) => type.includes('Suffix'))
  return {
    fullText,
    lang,
    order,
    prefixPart: prefixPart?.value,
    familyPart: familyPart?.value,
    givenPart: givenPart?.value,
    suffixPart: suffixPart?.value,
  }
}
