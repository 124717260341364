import React from 'react'
import { useTranslation } from 'react-i18next'
import Head from '@fs/zion-head'
import { usePageView } from '@fs/zion-analytics'
import { i18n } from '@fs/zion-locale'
import { AppLayout, colors } from '@fs/zion-ui'
import HomePageHeader from './HomePageHeader'
import HomePageBody from './HomePageBody'
import HomePageFooter from './HomePageFooter'
import PageHead from '../PageHead'
import shareImage from './assets/share-image.png'
import { appSupportedLocales } from '../../types/appSupportedLocales'

export default function HomePage(): JSX.Element | null {
  const [t] = useTranslation()
  const pageTitle = t('ancestors.home.meta-data.title')
  const pageDescription = t('ancestors.home.meta-data.description')
  const socialShareTitle = t('ancestors.home.meta-data.social-share-title')
  const socialShareDescription = t('ancestors.home.meta-data.social-share-description')
  const image = { url: shareImage, alt: pageTitle, width: 1024, height: 560 }
  const rootUrl = `${window?.location?.href?.substring(
    0,
    window.location.href.indexOf('/', window.location.href.indexOf('://') + 3)
  )}`
  const pageUrl = `${rootUrl}/${i18n.language}/`

  usePageView({ page_detail: 'Home' })

  if (!process.env.SSR && window?.location?.pathname === '/') {
    // Can't use history.push:
    window.location.href = `/${i18n.language}/`
    return null
  }

  return (
    <AppLayout fullWidth backgroundColor={colors.gray00}>
      <PageHead
        title={pageTitle}
        description={pageDescription}
        socialShareTitle={socialShareTitle}
        socialShareDescription={socialShareDescription}
        url={pageUrl}
        image={image}
      />
      <Head>
        {appSupportedLocales.map((locale) => {
          const langKey = `lang-${locale}`
          return <link key={langKey} rel="alternate" hrefLang={locale} href={`${rootUrl}/${locale}/`} />
        })}
        <link rel="alternate" hrefLang="x-default" href={`${rootUrl}/en/`} />
      </Head>
      <HomePageHeader />
      <HomePageBody />
      <HomePageFooter />
    </AppLayout>
  )
}
