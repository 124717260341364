import React, { useCallback } from 'react'
import { FormTextField, useZionFormContext, FormInputGroup } from '@fs/zion-form'
import { Cell, Grid, Row, useContainerWidth, H2 } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import PlaceField from './PlaceField'

const keyMap = {
  yearLabel: {
    birth: 'ancestors.birth.range.label',
    death: 'ancestors.death.range.label',
  },
  heading: {
    birth: 'ancestors.birth.header',
    death: 'ancestors.death.header',
  },
}

export default function EventFields({ type }: { type: 'birth' | 'death' }): JSX.Element {
  const [t] = useTranslation()
  const atWidth = useContainerWidth()
  const { getValues, setValue } = useZionFormContext()
  const isMobile = atWidth({ default: true, md: false })
  const yearLabel = isMobile ? t('ancestors.year.range.condensed-label') : t(keyMap.yearLabel[type])

  // Following functions auto fill date inputs left blank in order to encourage more narrow search parameters.
  const handleFromBlur = useCallback((): void => {
    const [dateBegin, dateEnd] = getValues([`${type}_date_begin`, `${type}_date_end`])
    if (dateBegin && !dateEnd) {
      setValue(`${type}_date_end`, dateBegin)
    }
  }, [getValues, setValue, type])

  const handleToBlur = useCallback((): void => {
    const [dateBegin, dateEnd] = getValues([`${type}_date_begin`, `${type}_date_end`])
    if (dateEnd && !dateBegin) {
      setValue(`${type}_date_begin`, dateEnd)
    }
  }, [getValues, setValue, type])

  const Wrapper = isMobile ? Grid : Row

  return (
    <Wrapper guttersY="none">
      <Cell growX>
        {isMobile && <H2 size="H6">{t(keyMap.heading[type])}</H2>}
        <PlaceField type={type} />
      </Cell>
      <FormInputGroup label={yearLabel}>
        <Grid guttersY="none">
          <FormTextField
            columns={6}
            placeholder={t('ancestors.from.label')}
            name={`${type}_date_begin`}
            data-testid={`${type}-range-from`}
            onBlur={handleFromBlur}
          />
          <FormTextField
            columns={6}
            placeholder={t('ancestors.to.label')}
            name={`${type}_date_end`}
            data-testid={`${type}-range-to`}
            onBlur={handleToBlur}
          />
        </Grid>
      </FormInputGroup>
    </Wrapper>
  )
}
