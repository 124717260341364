import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { colors, useContainerWidth, Paragraph } from '@fs/zion-ui'
import { Link } from '@fs/zion-router'
import ChurchLogo from './ChurchLogo'
import { useDataContext } from '../../services/data-service'

const footerCss = css`
  background: ${colors.gray00};
  min-height: 80px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & p {
    max-width: unset;
  }
`

const churchLogoCss = css`
  position: relative;
  margin-left: 24px;
`

function FooterLinks({ CF }: { CF: boolean }): JSX.Element {
  const [t] = useTranslation()

  const dateValues = { termsDate: '2023-09-01', privacyDate: '2021-04-06' }

  if (CF) {
    return (
      <>
        <Paragraph size="xs">
          <Trans i18nKey="ancestors.terms-privacy.text" values={dateValues}>
            <Link to="https://cf.familysearch.org/cfprgm/fs-terms-of-use" />
            <Link to="https://cf.familysearch.org/cfprgm/privacy-notice" />
          </Trans>
        </Paragraph>
        <div />
        <Paragraph size="xs">{t('ancestors.intellectual-reserve.restricted-text')}</Paragraph>
      </>
    )
  }

  return (
    <>
      <Paragraph size="xs">
        <Trans i18nKey="ancestors.terms-privacy.text" values={dateValues}>
          <Link to="https://www.familysearch.org/legal/terms" />
          <Link to="https://www.familysearch.org/legal/privacy" />
        </Trans>
      </Paragraph>
      <div />
      <Paragraph size="xs">
        <Trans i18nKey="ancestors.intellectual-reserve.text">
          <Link to="https://www.churchofjesuschrist.org" />
        </Trans>
      </Paragraph>
    </>
  )
}

export default function AncestorsFooter(): JSX.Element {
  const {
    store: { CF = false },
  } = useDataContext()
  const atWidth = useContainerWidth()
  const isMobile = atWidth({ default: true, lg: false })

  return (
    <footer css={footerCss}>
      <div>
        <FooterLinks CF={CF} />
      </div>
      {!isMobile && (
        <div css={churchLogoCss}>
          <ChurchLogo restricted={CF} />
        </div>
      )}
    </footer>
  )
}
